<template>
    <div class="customs-card">
        <div>
            <div style="color: #6A7884;">
                Declaration detailed view
            </div>

            <div class="text-header-primary">
                Custom {{ data.customs_declaration_id || '–' }}: <span class="uppercase">{{ data.status }} </span>
            </div>
        </div>
        <div class="customs-card__columns">
            <div class="flex flex-grow-1" style="gap: 16px;">
                <Documents class="w-1/2" style="min-width: 50%" />
                <Goods class="w-1/2" style="min-width: 50%" />
            </div>
        </div>
        <div class="mb-6">
            <div class="customs-card__title no-wrap mt-4 mb-2">
                Accompanying consignments
            </div>
            <div v-if="isLoading" class="relative flex flex__justify-center mt-4">
                <app-spinner :size="44" />
            </div>
            <div v-if="data.consignment" @dblclick="rowDbClick">
                <shipments-list-item
                    :key="data.consignment.uuid"
                    :value="data.consignment"
                    :show-bookmark="false"
                    class="border-radius--l"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Documents from '@/components/customs/documents.vue';
import Goods from '@/components/customs/goods.vue';
import axios from 'axios';
import ShipmentsListItem from '@/components/Shipments/ListItem.vue';
import AppSpinner from '~/components/AppSpinner';

export default {
    components: {
        Documents,
        Goods,
        ShipmentsListItem,
        AppSpinner,
    },
    data() {
        return {
            isLoading: true,
            data: {},
        };
    },
    methods: {
        rowDbClick() {
            window.open(`/transport/shipments/${this.data.consignment.uuid}`, '_blank');
        },
    },
    async created() {
        this.isLoading = true;
        
        const { data: { data } } = await axios.get(this.$apiUrl.customs.declarations + `/${this.$route.params.id}`);

        this.data = data || {};

        this.isLoading = false;
    },
}

</script>
<style lang="scss">

.customs-card__columns {
    margin-top: 32px;
    display: flex;
    gap: 16px;
}

.customs-card__payments,
.customs-card__documents,
.customs-card__goods {
    background: #fff;
    border-radius: 16px;
    padding: 16px;
}

.customs-card__delimeter {
    height: 2px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    background: #F7F7F7;
}

.customs-card__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
}

.customs-card__payments {
    &-list {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 16px;
    }
    &-item {
        display: flex;
        flex-direction: column;
        width: calc(50% - 6px);

        span:first-child {
            color: #6A7884;
        }
    }
}
</style>