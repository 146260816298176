import { render, staticRenderFns } from "./FreightModal.vue?vue&type=template&id=4a6bef82&scoped=true"
import script from "./FreightModal.vue?vue&type=script&lang=js"
export * from "./FreightModal.vue?vue&type=script&lang=js"
import style0 from "./FreightModal.vue?vue&type=style&index=0&id=4a6bef82&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a6bef82",
  null
  
)

export default component.exports