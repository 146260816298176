<template>
    <div class="emissions-page">
        <div class="flex w-full" v-if="!!kpiData">
            <div class="w-1/3">
                <h2 class="text-brand-400">
                    {{kpiData.co2eKg | kpiUnit()}}
                    <span>
                        <fa :icon="['fal','globe-europe']" class="text-gray-700" />
                    </span>
                    <div class="text-sm leading-tight text-gray-700 w-full font-normal" style="white-space:nowrap;">
                        Total CO2e kg emitted
                    </div>
                </h2>
            </div>
            <div class="w-1/3">
                <h2 class="text-brand-400">
                    {{kpiData.treesNeeded | kpiUnit()}}
                    <span>
                        <fa :icon="['fal','tree-alt']" class="text-gray-700" />
                    </span>
                    <div class="text-sm leading-tight text-gray-700 w-full font-normal" style="white-space:nowrap;">
                        Needed to absorb
                    </div>
                </h2>
            </div>
            <div class="w-1/3">
                <h2 class="text-brand-400">
                    {{kpiData.compensation | kpiUnit()}}
                    <span>
                        <fa :icon="['fal','euro-sign']" class="text-gray-700" />
                    </span>
                    <div class="text-sm leading-tight text-gray-700 w-full font-normal" style="white-space:nowrap;">
                        Cost for compensation
                    </div>
                </h2>
            </div>
        </div>
        <div class="mb-8 mt-2">
            <i class="text-sm text-gray-700">
                Figures for the last 3 months.
                <!-- <router-link :to="{ path: '/reports/emissions' }" class="text-brand-400">
                    See full report.
                </router-link> -->
            </i>
        </div>

        <filter-tools
            :filters="filters"
            name="TransportEmission"
            :filter-category="'emissions'"
            :key="`emissions-filter_${emissionsFilterCounter}`"
            @loaded="loadedFilters"
            @reset="resetFilters"
        >
            <div class="flex flex-col w-full">
                <div class="flex w-full mb-1">
                    <modal-field label="Date" class="w-1/3 pr-4">
                        <date-range-picker :loadedRange="loadedRange" @change="rangeChanged" />
                    </modal-field>

                    <modal-field label="Consignment no." class="w-1/3 pr-4">
                        <input type="text"
                            v-model="consignment_no"
                            v-on:blur="filters.consignment_no = consignment_no"
                            v-on:keyup.enter="filters.consignment_no = consignment_no" />
                    </modal-field>

                    <div class="flex flex__align-end w-1/3">
                        <modal-field label="Transport mode(s)" class="w-full">
                            <multi-select
                                v-model="filters.transport_mode"
                                :options="options.transport_mode"
                                multiple
                            >
                            </multi-select>

                        </modal-field>

                        <icon name="icons/settings" title="Show all filters" class="ml-3" @click="showAdvanced = !showAdvanced" />
                    </div>
                </div>

                <transition name="slide" mode="out-in">
                    <div class="flex flex-wrap w-full mt-4" v-if="showAdvanced">
                        <modal-field label="From city" class="w-1/3 pr-4 mb-4">
                            <multi-select
                                v-model="filters.consignor_pickup_city"
                                :options="clearEmptyValues(options.consignor_city)"
                                multiple
                            >
                            </multi-select>
                        </modal-field>

                        <modal-field label="From country" class="w-1/3 pr-4">
                            <multi-select
                                v-model="filters.consignor_pickup_countrycode"
                                :options="options.consignor_countrycode"
                                multiple
                            >
                            </multi-select>
                        </modal-field>

                        <modal-field label="Carrier" class="w-1/3">
                            <multiselect
                                v-model="carrier_id"
                                :options="options.carrier_id"
                                label="name"
                                track-by="id"
                                multiple
                            >
                            </multiselect>
                        </modal-field>

                        <modal-field label="To city" class="w-1/3 pr-4">
                            <multi-select
                                v-model="filters.consignee_delivery_city"
                                :options="clearEmptyValues(options.consignee_city)"
                                multiple
                            >
                            </multi-select>
                        </modal-field>

                        <modal-field label="To country" class="w-1/3 pr-4">
                            <multi-select
                                v-model="filters.consignee_delivery_countrycode"
                                :options="options.consignee_countrycode"
                                multiple
                            >
                            </multi-select>
                        </modal-field>

                        <modal-field label="Carrier service" class="w-1/3">
                            <multiselect
                                v-model="carrier_service_id"
                                :options="options.carrier_service_id"
                                group-label="name"
                                :group-select="false"
                                group-values="items"
                                label="name"
                                track-by="id"
                                multiple
                            >
                            </multiselect>
                        </modal-field>

                        <modal-field label="Filter site" class="w-1/3 pr-4 mt-4">
                            <multiselect
                                v-model="owner_site_from_source"
                                :options="userSites"
                                label="name"
                                track-by="id"
                                multiple
                                :disabled-select-all="true"
                                @input="updateOwnerSite"
                            >
                            </multiselect>
                        </modal-field>

                        <modal-field label="Price" class="w-1/3 pr-4 mt-4">
                            <input :value="filters.not_price" type="number" @input="updateNotPrice">
                        </modal-field>
                        <div style="width: 79px"><!-- Force spacing --></div>
                    </div>
                </transition>
            </div>

        </filter-tools>

        <div class="w-full mt-6">
            <card bodyClass="overflow-visible pl-0 pr-0 py-0" class="border-radius">
                <div class="flex flex-col w-full">
                    <v-table
                        id="emissions"
                        :columns="columns"
                        :endpoint="$apiUrl.emissions.base"
                        query-params
                        checkable
                        paginate
                        :tools="false"
                        :panel="true"
                        row-key="consignment_uuid"
                        ref="emissions"
                        sort-key="ordered_at"
                        custom-checkable
                        class="small-cell"
                        :filter-params="filterParams"
                        :sort-order="-1"
                        :loading="loadingEmissions || sitesLoading"
                        :selected="checkedRows.length"
                        :settings-icons-outline="false"
                        @check-row="rowChecked"
                        @row-click="rowClicked"
                    >
                        <template slot="panel">
                            <div class="relative flex items tools flex__align-center">
                                <icon
                                    name="icons/download"
                                    class="mr-4"
                                    title="Export" 
                                    @click="fetchExport"
                                />

                                <div class="w-1 h-6 mr-4 border-r" />
                            </div>
                        </template>
                        <template slot="cell" slot-scope="{row, col}" class="overflow-visible">
                            <span v-if="col.unit">
                                {{ row[col.key] | unit(col.unit) }}
                            </span>
                            <span v-else-if="col.key == 'pickup_city'">
                                {{ row['from'] }}
                            </span>
                            <span v-else-if="col.key == 'dimensions_volume'">
                                {{ row['dimensions_volume'] ? `${roundNumber(row['dimensions_volume'])} ${row['dimensions_volume_unit'] || ''}` : '' }}
                            </span>
                            <div
                                v-else-if="col.key == 'transport_mode'"
                                :title="row.transportmode"
                                class="pl-4 transportmode-icon"
                            >
                                <svg-importer :icon-name="`icons/${getTransportModeIcon(row.transport_mode)}`" />
                            </div>
                            <div
                                v-else-if="col.key == 'direction'"
                                class="transportmode-icon"
                                :class="row.direction ? 'pl-2' : 'pl-4'"
                            >
                                <svg-importer v-if="row.direction" :icon-name="`icons/${row.direction.toLowerCase()}`" />
                                <span v-else>
                                    -
                                </span>
                            </div>
                            <span v-else-if="col.key == 'delivery_city'">
                                {{ row['to'] }}
                            </span>
                            <span v-else-if="col.key === 'dimensions_loadingmetres'">
                                {{ roundWithEpsilon(row[col.key]) }}
                            </span>
                            <div 
                                v-else-if="col.key === 'is_green'"
                                class="transportmode-icon pl-2"
                            >
                                <svg-importer v-if="row.is_green" icon-name="icons/eco" />
                                <span v-else>
                                    -
                                </span>
                            </div>
                            <confirm v-else-if="col.key == 'actions' && isGodUser" class="block w-full text-right" confirm-text="Recalculate emissions" onclick="event.stopPropagation()" @confirmed="recalculate(row['consignment_uuid'])">
                                <svg-importer width="20" icon-name="icons/reset" :class="{'spinning': currentRecalculations.indexOf(row['consignment_uuid']) > -1}" />
                            </confirm>
                            <span v-else-if="col.key === 'fuel_amount'">
                                {{ row[col.key]  }} {{ row.fuel_amount_unit }}
                            </span>
                            <span v-else>{{ row[col.key] }}</span>
                        </template>
                    </v-table>
                </div>
            </card>
        </div>

        <emissions-details-dialog
            :id="selectedEmission ? selectedEmission.consignment_uuid : null"
            :showGotoShipment="true"
            :propFrom="selectedEmission ? selectedEmission.from.split(',')[0] : null"
            :propTo="selectedEmission ? selectedEmission.to.split(',')[0] : null"
            @hide="hideDetails"
        ></emissions-details-dialog>

        <emissions-calculate-uploaded-excel-dialog
            @hide="hideUploadExcel"
            :showing="showExcelUploadDialog"
        ></emissions-calculate-uploaded-excel-dialog>

        <emissions-import-consignment-dialog
            @hide="hideImportConsignment"
            :showing="showImportConsignmentDialog"
            :refreshTrigger="refreshView"
        ></emissions-import-consignment-dialog>
    </div>
</template>

<style lang="scss">
    .filter-btn {
        display: flex;
        align-items: flex-end;
    }

    @keyframes spin {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
    }

    .spinning{
        animation: spin 1.5s infinite linear;
    }

    .emissions-page {
        table.table tr:hover svg {
            rect.rect-hover {
                fill: #F6F6F6;
            }
        }
    }
</style>

<script>
    import axios from "axios";
    import moment from "moment";
    import { serializeParams } from "~/components/vtable/utils";
    import EmissionsDetailsDialog from "./EmissionsDetailsDialog";
    import EmissionsCalculateUploadedExcelDialog from "./EmissionsUploadCalculateExcelDialog";
    import EmissionsImportConsignmentDialog from "./EmissionsUploadImportConsignmentsDialog";
    import FilterTools from "~/components/FilterTools";
    import DateRangePicker from "~/components/DateRangePicker";
    import { roundNumber, roundWithEpsilon } from '@/utils/roundNumber.js';
    import emissionsColumns from './emissionColumns';

    export default {
        name: "Emissions",

        components: {
            EmissionsCalculateUploadedExcelDialog,
            EmissionsImportConsignmentDialog,
            EmissionsDetailsDialog,
            FilterTools,
            DateRangePicker
        },

        filters: {
            unit(value, unit) {
                if(value === null)
                    return '';

                if (value == 0)
                    return '0 ' + unit;

                let precision = 100;
                if (value >= 10)
                    precision = 10
                if (value >= 100)
                    precision = 1

                let val = Math.round(value * precision) / precision;

                return val == 0
                    ? '< 0,01 ' + unit
                    : val.toString().replace('.',',') + ' ' + unit;
            },
            kpiUnit(value) {
                if(value === null)
                    return '';

                if (value == 0)
                    return '0';

                let precision = 100;
                if (value >= 10)
                    precision = 10
                if (value >= 100)
                    precision = 1

                let val = Math.round(value * precision) / precision;

                const format = num => {
                    const n = String(num),
                        p = n.indexOf('.')
                    return n.replace(
                        /\d(?=(?:\d{3})+(?:\.|$))/g,
                        (m, i) => p < 0 || i < p ? `${m},` : m
                    ).replace(',', ' ').replace('.', ',');
                }

                return format(val);
            }
        },

        metaInfo() {
            return { title: "Emissions" };
        },

        data: () => ({
            emissionsFilterCounter: 0,
            notPriceTimer: null,
            selectedEmission: null,
            loadingEmissions: false,
            filterParams: {},
            carrier_id: [],
            carrier_service_id: [],
            owner_site_from_source: [],
            filters: {
                consignment_no: '',
                pickup_name: null,
                pickup_city: '',
                pickup_country: '',
                delivery_name: null,
                delivery_city: '',
                delivery_country: '',
                consignor_city: '',
                consignee_city: '',
                transport_mode: '',
                carrier_from_source: '',
                carrier_service_from_source: '',
                consignor_pickup_countrycode: '',
                consignee_delivery_countrycode: '',
                startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
                carrier_service_id: [],
                carrier_id: [],
                hepler_carrier_service_id: [],
                helper_carrier_id: [],
                not_price: '',
                owner_site_from_source: [],
                helper_owner_site_from_source: []
            },
            consignment_no: '',
            tempEmissionId: null,
            loadedRange: false,
            options: {
                consignor_city: [],
                consignor_countrycode: [],
                consignee_city: [],
                consignee_countrycode: [],
                transport_mode: [],
                carrier_from_source: [],
                carrier_service_from_source: [],
            },
            defaultFilters: {
                consignment_no: '',
                pickup_name: null,
                pickup_city: '',
                pickup_country: '',
                delivery_name: null,
                delivery_city: '',
                delivery_country: '',
                consignor_city: '',
                consignee_city: '',
                transport_mode: '',
                carrier_from_source: '',
                carrier_service_from_source: '',
                consignor_pickup_countrycode: '',
                consignee_delivery_countrycode: '',
                startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
                carrier_service_id: [],
                carrier_id: [],
                not_price: '',
                owner_site_from_source: [],
            },
            checkedRows: [],
            currentRecalculations: [],
            showExcelUploadDialog: false,
            showImportConsignmentDialog: false,
            kpiData: null,
            showAdvanced: false,
            spinner: null
        }),

        async created() {
            this.filters = this.copy(this.defaultFilters);
            this.filters.owner_site_from_source = this.userSites.map(i => i.key);
        },

        watch: {
            filters:{
                handler(){
                    this.setFilterParams();
                    this.fetchFilterOptions();
                    this.fetchKpiData();
                },
                deep: true
            },
            carrier_service_id() {
                this.filters.carrier_service_id = this.carrier_service_id?.map(item => { return item.id });
                this.filters.helper_carrier_service_id = this.carrier_service_id;
            },
            carrier_id() {
                this.filters.carrier_id = this.carrier_id?.map(item => { return item.id })
                this.filters.helper_carrier_id = this.carrier_id;
            },
            sites() {
                this.fetchKpiData();
                this.updateTable();
            },
            owner_site_from_source() {
                this.filters.owner_site_from_source = this.owner_site_from_source.length
                    ? this.owner_site_from_source.map(item => item.key)
                    : this.userSites.map(item => item.key);
                    
                this.filters.helper_owner_site_from_source = this.owner_site_from_source;
            },
            userSites() {
                this.actualizeOwnerSite()
            }
        },

        computed: {
            isGodUser() {
                return this.$store.getters['auth/isGodUser'];
            },
            isAdmin() {
                return this.$store.getters['auth/isAdmin'];
            },
            sitesLoading() {
                return this.$store.getters['auth/sitesLoading'];
            },
            user() {
                return this.$store.getters["auth/user"];
            },
            userSites() {
                return this.user.sitesFilter;
            },
            sites() {
                return this.$store.getters["auth/sites"];
            },
            meta() {
                return this.$store.getters["auth/user"].meta;
            },
            isClient() {
                return !this.isGodUser && !this.isAdmin;
            },
            columns() {
                return emissionsColumns.concat([
                    {
                        title: "Price",
                        key: "price",
                        toggled: false,
                        sortable: true,
                        width: "w-24"
                    },
                    {
                        title: "Total distance",
                        key: "total_distance",
                        toggled: false,
                        sortable: true,
                        width: "w-40"
                    },
                    {
                        title: "Green",
                        key: "is_green",
                        toggled: false,
                        sortable: false,
                        width: "w-40"
                    },
                    this.isGodUser ? {
                        title: "Tariff",
                        key: "tariff_name",
                        toggled: false,
                        sortable: false,
                        width: "w-24"
                    } : null,
                    this.isGodUser ? {
                        title: " ",
                        key: "actions",
                        toggled: true,
                        sortable: false,
                        width: "w-12",
                        overflow: true
                    } : null,
                ].filter(val => val));
            }
        },

        methods: {
            updateNotPrice(event) {
                clearTimeout(this.notPriceTimer);

                const value =  event?.target?.value;

                this.notPriceTimer = setTimeout(() => {
                    this.filters.not_price = value;
                }, 600);
            },
            updateOwnerSite(value) {
                this.owner_site_from_source = value;
            },
            actualizeOwnerSite() {
                const result = this.owner_site_from_source.filter(item => {
                    return this.userSites.some(i => item.id === i.id);
                });

                if (result.length) {
                    this.owner_site_from_source = result;
                } else {
                    this.owner_site_from_source = [];
                }
            },
            roundNumber(value) {
                return roundNumber(value);
            },
            roundWithEpsilon(value) {
                return roundWithEpsilon(value);
            },
            clearEmptyValues(list) {
                if (!list.length) {
                    return [];
                }

                return list.filter(item => { return item })
            },
            updateTable() {
                this.fetchFilterOptions();
                this.$refs.emissions?.table.fetchRows()
            },
            copy(obj) {
                return JSON.parse(JSON.stringify(obj));
            },

            hideDetails() {
                this.selectedEmission = null;
            },

            clearFilter(key) {
                this.filters[key] = this.defaultFilters[key];
            },

            async fetchExport() {
                const uuids = this.$refs.emissions.table.checkedRows;
                const columns = this.$refs.emissions.table.toggledColumns
                    .filter(item => { return item.key != 'actions' })
                    .map(item => { return { key: item.key, title: item.title } });
                const filter = this.filterParams;
                const sort = `sortKey=${ this.$route.query.sortKey }&sortOrder=${ this.$route.query.sortOrder }`;

                if (uuids.length) {
                    filter['filter[uuid]'] = uuids.join(',');
                } else {
                    delete filter['filter[uuid]']
                }

                const result = `?${sort}&${serializeParams(filter)}`;

                try {
                    await axios.post(`${this.$apiUrl.emissions.export}${result}`, { columns });

                    this.$snotify.success('Your task is in queue. Check your mailbox in 5 minutes');
                } catch(error) {
                    this.$snotify.error(error?.response?.data?.message);
                }
            },

            loadedFilters(filters) {
                this.resetFilters();

                Object.keys(filters).forEach((key) => {
                    this.filters[key] = filters[key] || ''
                });

                this.consignment_no = this.filters.consignment_no;
                this.transport_mode = this.filters.transport_mode;
                this.carrier_from_source = this.filters.carrier_from_source;
                this.carrier_service_from_source = this.filters.carrier_service_from_source;
                this.owner_site_from_source = this.filters.helper_owner_site_from_source;
                this.carrier_id = filters.helper_carrier_id;
                this.carrier_service_id = filters.helper_carrier_service_id;

                this.actualizeOwnerSite()
            },

            parseRangeDate() {
                if (!this.filters?.endDate) {
                    return this.filters.startDate;
                }

                return moment(this.filters.startDate).format('YYYY-MM-DD') + ',' + moment(this.filters.endDate).format('YYYY-MM-DD');
            },

            setFilterParams() {
                let params = {
                    'filter[date_between]': this.parseRangeDate(),
                }

                let keys = Object.keys(this.filters);

                keys.forEach(filter => {
                    if (this.filters[filter] && filter !== 'startDate' && filter !== 'endDate' && filter !== 'helper_carrier_id' && filter != 'helper_carrier_service_id' && filter != 'helper_owner_site_from_source') {
                        if (Array.isArray(this.filters[filter]) && !this.filters[filter].length) {
                            return;
                        }

                        params['filter[' + filter + ']'] = this.filters[filter].value ? this.filters[filter].value : this.filters[filter]
                    }
                })

                this.filterParams = params;
            },

            async fetchFilterOptions() {
                const filterParams = Object.assign({}, this.filterParams);

                if ('filter[not_price]' in filterParams) {
                    delete filterParams['filter[not_price]'];
                }

                const replaceKeys = [
                    { key: 'filter[carrier_id]', newKey: 'filter[carrier_ids]' },
                    { key: 'filter[carrier_service_id]', newKey: 'filter[carrier_service_ids]' },
                    { key: 'filter[consignor_pickup_city]', newKey: 'filter[consignor_city]' },
                    { key: 'filter[consignee_delivery_city]', newKey: 'filter[consignee_city]' },
                ];

                replaceKeys.forEach(item => {
                    if (filterParams[item.key]) {
                        const value = filterParams[item.key];

                        filterParams[item.newKey] = value;

                        delete filterParams[item.key]
                    }
                });

                const { data } = await axios.get(this.$apiUrl.filterOptions + `${serializeParams(filterParams)}`);

                let response = data.data;
                let keys = Object.keys(this.options)

                keys.forEach(option => {
                    const responseOption = option === 'transport_mode' ? response['transportservice_transportmode'] : response[option];
                    if(responseOption){
                        this.options[option] = responseOption;
                    }
                })

                const filteredCarriers = data.data.carrier_data.filtered_carriers;

                this.options['carrier_id'] = data.data.carrier_data.all_carriers;
                this.options['owner_data_list'] = data.data.owner_data.filtered_owners;
                this.options['owner_client_list'] = data.data.owner_data.all_owners;

                if (!filteredCarriers.length) {
                    this.options['carrier_service_id'] = [];
                }

                if (filteredCarriers.length) {
                    this.options['carrier_service_id'] = filteredCarriers.map(item => {
                        return {
                            name: item.carrier.name,
                            items: item.services,
                        }
                    });
                }
            },

            async fetchKpiData() {
                const { data } = await axios.get(this.$apiUrl.emissions.kpi);

                let response = data.data;
                this.kpiData = {
                    co2eKg: response.co2e_kg,
                    compensation: response.co2e_kg * 0.0158839,
                    treesNeeded: Math.ceil(response.co2e_kg / 22)
                }
            },

            cityChanged(group) {
                let idx = this.options[group].name.indexOf(this.filters[group + '_name']);
                if (idx !== -1) {
                    this.filters[group + '_city'] = this.options[group].city[idx];
                    this.filters[group + '_country'] = this.options[group].country[idx];
                }
                else {
                    this.filters[group + '_city'] = '';
                    this.filters[group + '_country'] = '';
                }
            },

            rangeChanged(range) {
                if (typeof range === 'string') {
                    this.filters.startDate = range;
                    this.filters.endDate = null;

                    return;
                }

                this.filters.startDate = moment(range.startDate).format('YYYY-MM-DD');
                this.filters.endDate = moment(range.endDate).format('YYYY-MM-DD');
            },

            rowClicked(row) {
                this.$nextTick(() => {
                    this.selectedEmission = row.data;
                })
            },

            resetFilters() {
                this.filters = this.copy(this.defaultFilters);
                this.consignment_no = '';
                this.transport_mode = '';
                this.carrier_from_source = '';
                this.carrier_service_from_source = '';
                this.carrier_id = [];
                this.carrier_service_id = [];
                this.owner_site_from_source = [];
                this.filters.helper_carrier_service_id = [];
                this.filters.helper_carrier_id = [];
                this.filters.helper_owner_site_from_source = [];
                this.emissionsFilterCounter++;
            },

            async recalculateMultiple(){
                this.spinner = this.$snotify.html(`<div class="flex flex-wrap" style="width: 100%;"><div class="w-3/4">Recalculating..</div><div class="w-1/4" style="position: relative"><svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="spinner-third" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fa-spinner svg-inline--fa fa-spinner-third fa-w-16 float-right" style="transform-origin: 0.5em 0.5em; position: absolute; top: 3px; left: 65px;"><g transform="translate(256 256)" class=""><g transform="translate(0, 0)  scale(1.375, 1.375)  rotate(0 0 0)" class=""><g transform="translate(-256 -256)" class="fa-group"><path fill="currentColor" d="M478.71 364.58zm-22 6.11l-27.83-15.9a15.92 15.92 0 0 1-6.94-19.2A184 184 0 1 1 256 72c5.89 0 11.71.29 17.46.83-.74-.07-1.48-.15-2.23-.21-8.49-.69-15.23-7.31-15.23-15.83v-32a16 16 0 0 1 15.34-16C266.24 8.46 261.18 8 256 8 119 8 8 119 8 256s111 248 248 248c98 0 182.42-56.95 222.71-139.42-4.13 7.86-14.23 10.55-22 6.11z" class="fa-secondary"></path><path fill="currentColor" d="M271.23 72.62c-8.49-.69-15.23-7.31-15.23-15.83V24.73c0-9.11 7.67-16.78 16.77-16.17C401.92 17.18 504 124.67 504 256a246 246 0 0 1-25 108.24c-4 8.17-14.37 11-22.26 6.45l-27.84-15.9c-7.41-4.23-9.83-13.35-6.2-21.07A182.53 182.53 0 0 0 440 256c0-96.49-74.27-175.63-168.77-183.38z" class="fa-primary"></path></g></g></g></svg></div></div>`,
                {
                    timeout: 0
                });

                this.checkedRows.forEach(x => {
                    this.currentRecalculations.push(x.consignment_uuid);

                    axios.patch(`${this.$apiUrl.emissions.shipment}/` + x.consignment_uuid + '/recalculate')
                    .then(() => {
                        this.checkMultiRecalculationDone(x.consignment_uuid);
                    }).catch(() => {
                        this.checkMultiRecalculationDone(x.consignment_uuid);
                    });
                });
            },

            checkMultiRecalculationDone(consignmentUuid){
                this.currentRecalculations.splice(this.currentRecalculations.indexOf(consignmentUuid), 1);
                this.recalculatingAmount--;

                if(this.currentRecalculations.length > 0){
                    return;
                }

                this.$snotify.remove(this.spinner.id);
                this.$snotify.success('Shipments has been recalculated');

                this.$refs.emissions.table.fetchRows();
                this.fetchKpiData();
            },

            async recalculate(consignmentUuid){
                if(this.currentRecalculations.indexOf(consignmentUuid) > -1){
                    return;
                }

                this.currentRecalculations.push(consignmentUuid);
                let statusCode = 0;

                try{
                    const data  = await axios.patch(this.$apiUrl.emissions.shipment + `/${consignmentUuid}` + '/recalculate');
                    statusCode = data.status;
                }catch(err){
                    statusCode = err.response.status;
                }

                if(statusCode === 200){
                    this.$snotify.success(`Emission values recalculated!`);
                    this.$refs.emissions.table.fetchRows();
                    this.fetchKpiData();
                }else if(statusCode === 422){
                    this.$snotify.error(`Emission values could not be recalculated!`);
                }

                this.currentRecalculations.splice(this.currentRecalculations.indexOf(consignmentUuid), 1);
            },

            showUploadExcel(){
                this.$nextTick(() => {
                    this.showExcelUploadDialog = true;
                });
            },

            hideUploadExcel(){
                this.showExcelUploadDialog = false;
            },

            showImportConsignment(){
                this.$nextTick(() => {
                    this.showImportConsignmentDialog = true;
                });
            },

            hideImportConsignment(){
                this.showImportConsignmentDialog = false;
            },

            rowChecked(row) {
                if(row == 'checkAllRows') {
                    this.checkedRows = this.$refs.emissions.table.visibleRows.slice();
                    return
                }

                if(row == 'uncheckAllRows') {
                    this.checkedRows = [];
                    return
                }

                if(this.checkedRows.indexOf(row) != -1) {
                    this.checkedRows.splice(this.checkedRows.indexOf(row), 1)
                } else {
                    this.checkedRows.push(row);
                }
            },

            refreshView(){
                this.$refs.emissions.table.commitState();
            }
        },
    };
</script>
<style lang="scss">
.small-cell {
    th, td {
        padding-right: 8px;
        padding-left: 8px;
    }
}
</style>
