
import { requestErrors } from '~/utils/errors';
import { validateEmailPlt } from '~/data/regex';
import axios from 'axios';
import moment from 'moment';

const payerDictionary = {
    sender: 'consignor',
    receiver: 'consignee',
}

const getAddServiceValue = (addService) => {
    if (addService.type === 'select') {
        return addService.value?.value || null;
    }

    return typeof addService.value === 'string' ? addService.value: String(addService.value);
};

const getAddServices = (result, services) => {
    services.forEach(addService => {
        if (addService.children.length) {
            getAddServices(result, addService.children);
        }

        if (addService.value === undefined) {
            return;
        }

        result.push({
            name: addService.name,
            value: getAddServiceValue(addService),
        });
    });
};

export default {
    data() {
        return {
            proxio: null,
            organizations: [],
            bookingData: null,
            showBookingModal: false,
            loadingUnifaun: false,
            showDocumentsModal: false,
            loadingDocuments: false,
            apportServiceLink: '',
            bookingText: 'Your booking draft was successfully created. Please find it in vOne and submit to the carrier.',
            isAzureInviting: false,
            azureModals: {
                warning: {
                    show: false,
                    header: 'Azure authorization is not complete',
                    body: 'Unfortunately, as you are not registered in our Azure AD, on creating booking draft you will not be transferred to vOne to finalize it (booking draft will be created)',
                },
                inviteSuccess: {
                    show: false,
                    header: 'Azure authorization is complete',
                    body: 'Thank you! We have sent an invitation letter to your e-mail. Please follow the instruction to finalize registration in Azure AD. On finalizing registration it may take up to 20 minutes to sync ONE platform with Azure. Sorry for inconvenience.',
                },
            },
            payerTypes: [
                { id: 'sender', name: 'Sender'},
                { id: 'receiver', name: 'Receiver'},
                { id: 'third_party', name: 'Third party'},
            ],
            documents: [],
            customsData: {
                invoicenumber: '',
                invoicedate: moment(),
                exportreason: '',
                shipmenttype: '',
                othercomments: '',
                declarationstatement: '',
                freightcharges: '',
                goods: [{}],
                valueOfGoodsAmount: '',
                valueOfGoodsCurrency: '',
                valueOfGoodsDutiable: true,
            },
            isBookingLoading: false,
            createBookingErrors: [],
            updatePayload: {
                pickupTime: (payload) => {
                    payload.earliestPickupTime = this.getPickupTime(this.allBookingData.address.pickup.pickupTimeEarliest.value);
                    payload.latestPickupTime = this.getPickupTime(this.allBookingData.address.pickup.pickupTimeLatest.value);
                },
                loadMeters: (payload) => {
                    this.allBookingData.goods.items.forEach((item, index) => {
                        payload.goods[index].load_meters = item.load_meters;
                    });
                },
                nonStackable: (payload) => {
                    this.allBookingData.goods.items.forEach((item, index) => {
                        payload.goods[index].stackable = !item.non_stackable;
                    });
                },
                customsClearance: (data) => {
                    data.customsClearance = [];
        
                    this.customsData.goods.forEach(item => {
                        data.customsClearance.push(
                            {
                                invoiceNo: item.invoiceNo,
                                customsDescription: item.customsDescription,
                                countryOfOrigin: item.countryOfOrigin.code,
                                statisticalNo: item.statisticalNo,
                                documentType: item.documentType?.value,
                                goodsType: item.goodsType,
                                goodsDescription: item.goodsDescription,
                                netWeight: item.netWeight,
                                grossWeight: item.netWeight,
                                invoiceDate: item.invoiceDate ? item.invoiceDate.format('YYYY-MM-DD') : null,
                                unitOfMeasureCode: item.unitOfMeasureCode,
                                unitPrice: item.unitPrice,
                                numberOfGoodsItems: item.numberOfGoodsItems,
                                dutiable: Boolean(this.customsData.valueOfGoodsDutiable),
                            }
                        );
                    });
                },
                customsData: (data) => {
                    data.invoice = {
                        invoicenumber: this.customsData.invoicenumber,
                        invoicedate: this.customsData.invoicedate.format('YYYY-MM-DD'),
                        exportreason: this.customsData.exportreason.value,
                        shipmenttype: this.customsData.shipmenttype.value,
                        othercomments: this.customsData.othercomments,
                        declarationstatement: this.customsData.declarationstatement,
                        freightcharges: this.customsData.freightcharges,
                    };
                    
                },
                customsValue: (data) => {
                    data.valueofgoods = {
                        valueOfGoodsAmount: this.customsData.valueOfGoodsAmount,
                        valueOfGoodsCurrency: this.customsData.valueOfGoodsCurrency,
                        valueOfGoodsDutiable: Boolean(this.customsData.valueOfGoodsDutiable),
                    };
                },
            },
        };
    },
    computed: {
        additionalServices() {
            return this.$store.state['price-leadtime'].additionalServices;
        },
        modules() {
            return this.$store.state['price-leadtime'].modules;
        },
        modulesStates() {
            return this.$store.state['price-leadtime'].modulesStateDictionary;
        },
        apportSsoLink() {
            return this.$store.getters['auth/ssoUnifaunLink'];
        },
        authSites() {
            return this.$store.getters['auth/sites'];
        },
        isTemplate() {
            const routeSplitted = this.$route.name.split('.');

            return  routeSplitted[0] === 'transport' && routeSplitted[2] === 'template';
        },
        priceLeadTimeResult() {
            return this.$store.getters[`price-leadtime/getPriceLeadTimeResult`];
        },
        allBookingData() {
            return this.$store.getters['price-leadtime/all'];
        },
        deliveryTerms() {
            return this.$store.getters['price-leadtime/delivery_terms'];
        },
        isShortFlow() {
            return this.$store.getters['price-leadtime/isShortFlow'];
        },
        addressData() {
            return this.$store.getters['price-leadtime/address'];
        },
        goodsData() {
            return this.$store.getters['price-leadtime/goods'].items;
        },
        deliveryTermsData() {
            return this.$store.getters['price-leadtime/delivery_terms'];
        },
        validateAddress() {
            const data = Object.entries(this.addressData);

            const result = data.filter(item => {
                let valid = true;

                if (!item[1].zip?.trim()) {
                    valid = false;
                }

                if (!item[1].country) {
                    valid = false;
                }

                if (!item[1].name) {
                    valid = false;
                }

                if (item[1].email && !validateEmailPlt(item[1].email)) {
                    valid = false;
                }

                if (
                    this.isShortFlow
                    && !(item[1].phone && item[1].email && item[1].contact_person && item[1].address && item[1].city)
                ) {
                    valid = false
                }

                if (!('pickup_date' in item[1])) {
                    return valid;
                }

                return Boolean(valid && item[1].pickup_date);
            });

            return Boolean(data.length === result.length && this.deliveryTerms.payer);
        },
        currentStep() {
            return this.$store.getters["price-leadtime/currentStep"];
        },
        currentStepKey() {
            return this.steps[this.currentStep].key
        },
        steps() {
            return this.$store.getters['price-leadtime/activeSteps']
        },
        validateGoods() {
            const valid = this.goodsData.filter(item => {
                const hasValidDimensions = this.validateGoodsLineDimensions(item);
                if (this.currentStep === 1 && this.isShortFlow || this.isTemplate) {
                    return (
                        item.units
                        && item.weight_line
                        && hasValidDimensions
                        && item.description
                        && item.package_type
                        && this.priceLeadTimeResult?.carrier_model?.id
                        && this.priceLeadTimeResult?.carrier_service_model
                    );
                }

                return Boolean(item.units && item.weight_line && item.description && hasValidDimensions);
            });

            return Boolean(valid.length === this.goodsData.length);
        },
        validatePriceLt() {
            const valid = this.goodsData.filter(item => {
                return item.units && item.weight_line && item.package_type && item.description;
            });

            return Boolean(valid.length === this.goodsData.length);
        },
        validateCreateBooking() {
            const data = Object.values(this.addressData).filter(item => {
                return item.active;
            });

            const result = data.filter(item => {
                const valid = 
                    item.name?.trim() &&
                    item.address?.trim() &&
                    item.city?.trim() &&
                    item.zip?.trim() && 
                    item.contact_person?.trim() &&
                    item.phone?.trim() &&
                    item.email?.trim() &&
                    item.country;

                if (!('pickup_date' in item)) {
                    return valid;
                }

                return valid && item.pickup_date;
            });

            return Boolean(data.length === result.length);
        },
    },
    methods: {
        formatDatetimeToTime(date) {
            if (!date) {
                return null;
            }

            return moment(date).format('HH:mm');
        },
        redirectToPltStart() {
            this.$router.push({ name: this.$route.name.split('.').filter((_, index) => index < 2).join('.') });
        },
        resetCustomsData() {
            this.invoicenumber = '';
            this.invoicedate = moment();
            this.exportreason = '';
            this.shipmenttype = '';
            this.othercomments = '';
            this.declarationstatement = '';
            this.freightcharges = '';
            this.goods = [{}];
            this.valueOfGoodsAmount = '';
            this.valueOfGoodsCurrency = '';
            this.valueOfGoodsDutiable = true;
        },
        closeDocumentsModal() {
            this.showDocumentsModal = false;
            this.documents = [];
            
            this.redirectToPltStart();
        },
        hideBookdingModal() {
            this.showBookingModal = false;
            this.bookingData = null;
            this.$store.commit('price-leadtime/resetState');

            this.redirectToPltStart();
        },
        checkAzureId() {
            if (this.user.azure_id) {
                return;
            }

            this.azureModals.warning.show = true;
        },
        async inviteAzure() {
            this.isAzureInviting = true;

            try {
                await axios.post(this.$apiUrl.users.inviteAzure(this.user.id));

                this.azureModals.inviteSuccess.show = true;
            } catch(error) {
                this.$snotify.error(requestErrors(error));
            }

            this.isAzureInviting = false;
        },
        closeAzureModals() {
            this.azureModals.warning.show = false;
            this.azureModals.inviteSuccess.show = false;
        },
        async fetchProxio() {
            if (!this.uuid) {
                return;
            }

            try {
                const result = await axios.get(`/api/consignments/${this.uuid}/proxio`);

                this.proxio = result.data.data;
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            }
        },
        async fetchOrganizations() {
            let { data: { data } } = await axios.get(`${this.$apiUrl.organizations.base}?pageSize=1000&include=sites`);

            if (!this.isGodUser) {
                data = data.filter(organization => this.authSites?.find(site => site.organization.id === organization.id));
            }

            this.organizations = data;
        },
        validateGoodsLineDimensions(line) {
            // isShowCheckServiceButton is a computed
            // defined in src/pages/transport/PriceLeadTimeFormTemplate.vue 
            if (this.isShowCheckServiceButton) {
                return line.depth && line.width && line.height;
            }

            if (this.$store.getters['price-leadtime/modulesVisibility']['load-meters-module']) {
                return line.load_meters || line.volume_line || (line.depth && line.width && line.height);
            }

            return line.volume_line || (line.depth && line.width && line.height);
        },
        getBookingData(templateId) {
            const address = this.allBookingData.address;
            const deliveryTerms = this.allBookingData.deliveryTerms;
            const goods = this.allBookingData.goods;

            const result = {
                fromName: address.consignor.name,
                fromCountryCode: address.consignor.country.value,
                fromZipCode: address.consignor.zip,
                fromAddress: address.consignor.address,
                fromAddress2: address.consignor.address2,
                fromCity: address.consignor.city,
                fromState: address.consignor.state,
                fromContactName: address.consignor.contact_person,
                fromContactPhone: address.consignor.phone,
                fromContactEmail: address.consignor.email?.replace(' ', ''),
                fromUnifaunAddressId: address.consignor.unifaun_address_id,

                toName: address.consignee.name,
                toCountryCode: address.consignee.country.value,
                toZipCode: address.consignee.zip,
                toAddress: address.consignee.address,
                toAddress2: address.consignee.address2,
                toCity: address.consignee.city,
                toState: address.consignee.state,
                toContactName: address.consignee.contact_person,
                toContactPhone: address.consignee.phone,
                toContactEmail: address.consignee.email?.replace(' ', ''),
                toUnifaunAddressId: address.consignor.unifaun_address_id,

                senderName: address.pickup.name,
                senderCountryCode: address.pickup.country.value,
                senderZipCode: address.pickup.zip,
                senderAddress: address.pickup.address,
                senderAddress2: address.pickup.address2,
                senderCity: address.pickup.city,
                senderState: address.pickup.state,
                senderContactName: address.pickup.contact_person,
                senderContactPhone: address.pickup.phone,
                senderContactEmail: address.pickup.email?.replace(' ', ''),
                senderUnifaunAddressId: address.consignor.unifaun_address_id,

                receiverName: address.delivery.name,
                receiverCountryCode: address.delivery.country.value,
                receiverZipCode: address.delivery.zip,
                receiverAddress: address.delivery.address,
                receiverAddress2: address.delivery.address2,
                receiverCity: address.delivery.city,
                receiverState: address.delivery.state,
                receiverContactName: address.delivery.contact_person,
                receiverContactPhone: address.delivery.phone,
                receiverContactEmail: address.delivery.email?.replace(' ', ''),
                receiverUnifaunAddressId: address.consignor.unifaun_address_id,

                carrierServiceId: this.priceLeadTimeResult?.carrier_service_model?.id,

                payer: payerDictionary[deliveryTerms.payer] || 'third party',
                pickupInstructions: deliveryTerms.pickup_instructions?.instruction,
                deliveryInstructions: deliveryTerms.delivery_instructions?.instruction,

                pickupDate: moment.isMoment(address.pickup.pickup_date) ? address.pickup.pickup_date.format('YYYY-MM-DD') : '',

                isDailyPickup: false,
                senderReference: deliveryTerms.senders_reference,
                recipientReference: deliveryTerms.recipients_reference,

                goods: goods.items.map(item => {
                    let volume = +item.volume_line || (+item.depth / 100) * (+item.width / 100) * (+item.height / 100);

                    if (isNaN(volume)) {
                        volume = null;
                    }

                    return {
                        units: item.units,
                        weight: item.weight_line,
                        pallet_spaces: item.pallet_spaces,
                        volume: volume,
                        height: item.height,
                        length: item.depth,
                        width: item.width,
                        package_type: item.package_type?.code,
                        shipment_mark: item.shipping_mark,
                        description: item.description,
                    };
                }),
            };

            if (templateId) {
                result.templateId = templateId;
            }

            if (this.modules['load-meters-module'] === this.modulesStates.visible) {
                this.updatePayload.loadMeters(result);
            }

            if (this.modules['non-stackable-module'] === this.modulesStates.visible) {
                this.updatePayload.nonStackable(result);
            }

            if (this.modules['pickup-time-module'] === this.modulesStates.visible) {
                this.updatePayload.pickupTime(result);
            }

            if (this.modules['custom-data-module'] === this.modulesStates.visible) {
                this.updatePayload.customsData(result);
            }

            if (this.modules['custom-value-module'] === this.modulesStates.visible) {
                this.updatePayload.customsValue(result);
            }

            if (this.modules['custom-clearance-module'] === this.modulesStates.visible) {
                this.updatePayload.customsClearance(result);
            }

            if (this.$store.state['price-leadtime'].transport_mode) {
                result.transport_mode = this.$store.state['price-leadtime'].transport_mode;
            }

            result.addServices = [];

            getAddServices(result.addServices, this.additionalServices);

            if (this.$store.state['price-leadtime'].attachments?.length) {
                result.attachments = this.$store.state['price-leadtime'].attachments;
            }

            return this.objectToFormData(result);
        },
        async fetchUnifaunUrl() {
            this.loadingUnifaun = true;

            const deliveryTerms = this.allBookingData.deliveryTerms;
            const params = {
                shipper_ref: deliveryTerms.senders_reference,
                consignee_ref: deliveryTerms.recipients_reference,
                consignment_no: this.bookingData.consignmentNo,
            }

            try {
                const response = await axios.post(this.$apiUrl.findBookingUrl, params);

                if (response?.data?.data?.url) {
                    this.apportServiceLink = response.data.data.url;
                }

            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }

            this.loadingUnifaun = false;
            this.showBookingModal = true;
        },
        async sendPrintDocuments() {
            this.showDocumentsModal = true;
            this.loadingDocuments = true;

            try {
                const { data: { data } } = await axios.post(
                    this.$apiUrl.apport.print, 
                    { consignmentId: this.bookingData.consignmentId, consignmentNo: this.bookingData.consignmentNo }
                );
                
                this.documents = data;
            } catch (error) {
                this.$snotify(requestErrors(error));
            }
            
            this.loadingDocuments = false;
        },
        async sendCreateBooking(templateId) {
            await this.createBookingDraft(templateId);
            

            if (this.bookingData?.consignmentNo && this.bookingData?.status === 'success') {
                this.sendPrintDocuments(templateId);

                return;
            }

            if (this.bookingData?.consignmentId) {
                this.fetchUnifaunUrl();
            }
        },
        async createBookingDraft(templateId) {
            this.isBookingLoading = true;
            this.createBookingErrors = [];
    
            try {
                const payload = this.getBookingData(templateId);
                const axiosParams = payload?.attachments ? {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                } : {};

                const { data: { data } } = await axios.post(this.$apiUrl.apport.order, payload, axiosParams);

                if (data.status === 'fail') {
                    this.createBookingErrors = data.errors;
                    this.isBookingLoading = false;

                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });

                    return;
                }

                if (this.payloadData) {
                    this.payloadData = null;
                }
    
                if (data.consignmentId) {
                    this.$store.dispatch('auth/fetchUser');
                    this.bookingData = {
                        consignmentId: data.consignmentId,
                        consignmentNo: data.consignmentNo,
                        orderNo: data.orderNo,
                        status: data.status,
                    };

                    if (this.bookingData.consignmentNo && this.bookingData.status === 'success') {
                        this.isBookingLoading = false;

                        return;
                    }
            
                    this.showBookingModal = true;
                }
            } catch (error) {
                this.$snotify.error(requestErrors(error));
    
                if (this.bookingData) {
                    this.loadingUnifaun = false;
                }

                if (this.payloadData) {
                    this.payloadData = null;
                }
            }

            this.isBookingLoading = false;
        },
    },
}