<template>
    <modal :show="show" size="sm" @hide="$emit('hide')">
        <h2 slot="header">Data export settings</h2>

        <div slot="body" class="export-modal-body">
            <div v-show="!loading" class="mr-2 px-6 border__bottom-grey_not-last">
                <label class="flex flex__justify-between items-center" style="height: 56px">
                    <span class="flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            class="form-checkbox"
                            :checked="isAllChecked"
                            @change="toggleAllChecked"
                        />
                        <span class="ml-2">All</span>
                    </span>
                </label>
            </div>
            <div v-for="(col, cIndex) in columnsFiltered" :key="cIndex" class="mr-2 px-6 border__bottom-grey_not-last">
                <label class="flex flex__justify-between items-center" style="height: 56px" v-if="col.key != 'actions'">
                    <span class="flex items-center cursor-pointer">
                        <input
                            v-model="pickedColumns"
                            :value="col"
                            type="checkbox"
                            class="form-checkbox"
                            :disabled="loading"
                        />
                        <span class="ml-2">{{ col.title }}</span>
                    </span>
                </label>
            </div>
        </div>

        <div slot="footer" class="relative w-full flex justify-center">
            <spinner v-show="loading" />

            <button type="button" class="btn" :disabled="loading || !pickedColumns.length" @click="exportCols">
                Export
            </button>
        </div>
    </modal>
</template>
<script>
const EXCLUDED_COLUMNS = ['exception-details', 'goods_description', 'purchase_orders'];
const LS_NAME = 'client_picked_columns';

const ADDITIONAL_COLUMNS = [
    {
        title: 'Actual pickup date',
        key: 'actual_time_pick_up_at',
        toggled: false,
        sortable: false,
        pin: false,
        width: '',
    },
    {
        title: 'Recipient\'s ref.',
        key: 'consignee_reference',
        toggled: false,
        sortable: false,
        pin: false,
        width: '',
    },
    {
        title: 'Booking template',
        key: 'template',
        toggled: false,
        sortable: false,
        pin: false,
        width: '',
    },
    {
        title: 'Requested pickup',
        key: 'pick_up_starts_at',
        toggled: false,
        sortable: false,
        pin: false,
        width: '',
    },
    {
        title: 'Estimated Departure Date',
        key: 'estimated_time_departure_at',
        toggled: false,
        sortable: false,
        pin: false,
        width: '',
    },
    {
        title: 'Actual Departure Date',
        key: 'actual_time_departure_at',
        toggled: false,
        sortable: false,
        pin: false,
        width: '',
    },
    {
        title: 'Estimated Delivery Date',
        key: 'estimated_delivery_starts_at',
        toggled: false,
        sortable: false,
        pin: false,
        width: '',
    },
    {
        title: 'Estimated Arrival Date',
        key: 'estimated_arrival_at',
        toggled: false,
        sortable: false,
        pin: false,
        width: '',
    },
    {
        title: 'Actual Arrival Date',
        key: 'actual_time_arrival_at',
        toggled: false,
        sortable: false,
        pin: false,
        width: '',
    },
    {
        title: 'Plate no',
        key: 'plate_no',
        toggled: true,
        width: 'w-24',
        pin: false,
        sortable: true
    },
];

import Spinner from '~/components/SpinnerBlock';

export default {
    components: {
        Spinner,
    },
    props: {
        show: Boolean,
        columns: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        show: {
            immediate: true,
            handler(val) {
                if (!val) {
                    this.loading = false;

                    return;
                }

                if (!localStorage.getItem(LS_NAME)) {
                    return;
                }

                try {
                    this.pickedColumns = JSON.parse(localStorage.getItem(LS_NAME));
                } catch (error) {
                    this.pickedColumns = [];
                }

            },
        },
    },
    computed: {
        columnsFiltered() {
            return [
                this.columns.filter(column => !EXCLUDED_COLUMNS.includes(column.key)),
                ADDITIONAL_COLUMNS,
            ].flat();
        },
        isAllChecked() {
            return this.columnsFiltered.length === this.pickedColumns.length;
        },
    },
    data() {
        return {
            pickedColumns: [],
            loading: false,
        };
    },
    methods: {
        exportCols() {
            this.loading = true;
            const columnsToExport = this.pickedColumns?.filter(col => !EXCLUDED_COLUMNS.includes(col.key)) || [];

            localStorage.setItem(LS_NAME, JSON.stringify(columnsToExport));
            this.$emit('export', columnsToExport);
        },
        toggleAllChecked() {
            if (this.isAllChecked) {
                this.pickedColumns = [];

                return;
            }

            this.pickedColumns = [...this.columnsFiltered];
        },
    },
}
</script>
<style lang="scss" scoped>
.export-modal-body {
    height: 440px;
    overflow: auto;
    margin: 0 -1.5rem;
}
</style>