<template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="sm"
        @hide="$emit('hide')"
    >
        <div slot="header">
            <h2 class="font-normal">Waybill</h2>
        </div>

        <div slot="body">
            <form @submit.prevent="">
                <modal-field label="Waybill">
                    <input 
                        v-model="waybill_number"
                        class="w-full"
                        type="text"
                    />
                </modal-field>
            </form>
        </div>
        
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full">
                <div class="flex">
                    <button class="px-10 btn-primary" @click="save">Save</button>
                    
                    <button class="px-8 ml-4 btn-grey-outline" @click="$emit('hide')">Cancel</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'Waybill',

    props: {
        value: {
            type: Object,
            default: () => { return {} },
        }
    },

    data() {
        return {
            waybill_number: this.value.waybill_number,
        }
    },

    methods: {
        save() {
            this.$emit('save', this.waybill_number);
            this.$emit('hide');
        }
    },
}
</script>
