<template>
    <div>
        <page-header title="Consignment data">
            <template slot="button">
                <transition name="fade">
                    <button
                        v-if="isGodUser && this.$route.params.id"
                        type="button"
                        class="btn mr-2"
                        :disabled="!isAllowEditTemplate || isLoading"
                        :class="{ 'btn-loading': isLoading }"
                        @click="showEditModal"
                    >
                        Update template
                    </button>
                </transition>
                <save-new-template-button v-if="isGodUser" @click="showCreateModal" />
                <router-link :to="{ name: manageTemplatesLink }" type="button" class="btn mr-2">
                    Manage templates
                </router-link>
            </template>
        </page-header>
        <card bodyClass="overflow-visible" class="mb-4">
            <div class="flex flex__column w-full">
                <div class="flex w-full">
                    <modal-field>
                        <div class="flex">
                            <div class="mr-3">
                                <span>Freight payer</span>
                                <span class="text-red-500">*</span>
                            </div>
                            <label v-for="payerType in payerTypes" :key="payerType.id" class="flex flex__align-center mr-3">
                                <input
                                    v-model="deliveryTermsData.payer"
                                    :disabled="getIsLockedField('payer')"
                                    type="radio"
                                    :value="payerType.id"
                                    class="form-radio mr-2"
                                />
                                <div :for="payerType.id" class="f-size-12">{{ payerType.name }}</div>
                            </label>
                        </div>
                    </modal-field>
                    <confirm v-if="isFormAccessable" class="mr-4 ml-auto hover:text-red-500" confirm-text="Reset form" @confirmed="$route.params.id ? resetPage() : resetData()">
                        <fa class="mr-1" :icon="['fal','undo']"/>
                        Reset
                    </confirm>
                </div>
                <div class="flex flex__align-end w-full">
                    <modal-field class="w__220 pr-4">
                        <autocomplete
                            v-model="currentTemplateSearch"
                            :ref="templatesAutocompleteRef"
                            :endpoint="templatesSearchEndpoint"
                            :immediate-empty-search="true"
                            :on-options-loaded="data => templates = data"
                            placeholder="Select template"
                            id="template-autocomplete"
                            @click="changeTemplate($event.id)"
                        >
                            <template v-slot="slotProps">
                                <div
                                    class="template-autocomplete__item"
                                    @mouseenter="hintValue = slotProps.data.full_description"
                                >
                                    {{ slotProps.data.name }}
                                    <span v-if="slotProps.data.unifaun_pk" class="mr-2 text-gray-800">
                                        (sync)
                                    </span>
                                    <span class="mr-2 text-gray-600">
                                        {{ slotProps.data.description }}
                                    </span>
                                </div>
                            </template>

                            <template #afterlist>
                                <hint target-element="cinput-autocomplete__el" :value="hintValue" />
                            </template>
                        </autocomplete>
                    </modal-field>

                    <modal-field
                        required
                        :disabled="currentStep == 3"
                        :compact="true"
                        class="w__220"
                        :class="{ empty: getIsEmptyField(addressData['pickup'].pickup_date) }"
                    >
                        <date-picker
                            v-model="addressData['pickup'].pickup_date"
                            :disable-past="true"
                            :format-on-emit="true"
                            :disabled="!isFormAccessable || (currentStepKey === 'create_booking' && Boolean(addressData['pickup'].pickup_date))"
                            placeholder="Select transport date"
                        />
                    </modal-field>

                    <quote-module name="pickup-time-module" class="flex">
                        <modal-field
                            v-for="pickupTime in [addressData.pickup.pickupTimeEarliest, addressData.pickup.pickupTimeLatest]"
                            :key="`pickupTime_${pickupTime.label}`"
                            :label="`${pickupTime.label} pickup`"
                            class="pl-4"
                        >   
                            <date-time-picker
                                v-model="pickupTime.value"
                                :disabled-time="getDisabledTime(pickupTime.key)"
                                type="time"
                                placeholder="Select time"
                                :show-second="false"
                                :minute-step="15"
                                :disabled="getIsLockedField('pickup_time')"
                                format="HH:mm"
                            />
                        </modal-field>
                    </quote-module>
                </div>
            </div>
        </card>

        <div v-if="createBookingErrors.length">
            <div v-for="item, index in createBookingErrors" :key="index" class="color-error">
                {{ item }}
            </div>
        </div>

        <price-leadtime-address :proxio="proxio" :organizations="organizations" :component-counter-prop="componentCounter" />
        <goods :dutiable="isDutiable" :needs-service-check="isShowCheckServiceButton" />

        <dropdown-block
            title="Instructions & referencies"
            :title-hidden="false"
            :default-show="true"
        >
            <div>
                <delivery-terms />
            </div>
        </dropdown-block>

        <customs-block v-model="customsData" />

        <dropdown-block
            title="Attachments"
            :title-hidden="false"
            :default-show="true"
        >
            <card>
                <attachments />
            </card>
        </dropdown-block>

        <card body-class="flex__justify-center">
            <button
                v-if="isShowCheckServiceButton"
                type="button"
                class="btn mr-2"
                :class="{ 'btn-loading': isLoadingDutiableServicesCheck }"
                :disabled="!isAllValid"
                @click="sendDutiableServicesCheck(currentTemplate ? currentTemplate.id : null)"
            >
                Check service (dutiable suspect)
            </button>
            <button
                type="button"
                class="btn"
                :disabled="isCreateBookingDisabled"
                :class="{ 'btn-loading': isBookingLoading }"
                @click="sendCreateBooking(currentTemplate ? currentTemplate.id : null)"
            >
                Create booking
            </button>
        </card>

        <modal :show="isShowModal" @hide="hideModal" :loader="loading" size="sm">
            <h2 slot="header">
                <span v-show="modalType === 'create'">
                    Saving new booking template
                </span>
                <span v-show="modalType === 'edit'">
                    Edit booking template
                </span>
            </h2>
            <template slot="body">
                <div>
                    <modal-field label="Name" required>
                        <input type="text" v-model="name" />
                    </modal-field>
                    <modal-field label="Description" class="mt-2" required>
                        <input type="text" v-model="description" />
                    </modal-field>
                    <modal-field v-if="isGodUser" label="Organization" class="mt-2" required>
                        <multiselect
                            v-model="currentOrganization"
                            :options="organizations"
                            track-by="id"
                            label="name"
                        >
                        </multiselect>
                    </modal-field>
                    <modal-field v-if="isGodUser || isAdmin" label="Template visibility" class="mt-2" required>
                        <app-spinner v-show="isLoadingUsers" key="template-users-visibility" />
                        <multiselect
                            v-show="!isLoadingUsers"
                            v-model="usersComputed"
                            :options="usersOptions"
                            multiple
                            track-by="id"
                            label="name"
                        >
                        </multiselect>
                    </modal-field>
                    <modal-field label="Full description" class="mt-2">
                        <input type="text" v-model="fullDescription" >
                    </modal-field>
                </div>
                <div class="mt-4 template-modal-list">
                    <div class="flex template-modal-list__head">
                        <span class="font-bold">Template data</span>
                        <span class="font-bold text-center ml-auto pr-1 w-12">Save</span>
                        <span class="font-bold text-center ml-12 pr-1 w-12">Lock</span>
                    </div>
                    <div v-for="(item, key, index) in modalData" :key="item.name" class="py-1 flex" :class="{ 'template-modal-row': index % 2 === 0 }">
                        <span>{{ item.name }}</span>
                        <checkbox v-model="item.save" :checked="item.save" :id="key" :disabled="item.saveDisabled" class="w-12 ml-auto justify-center" @change="onSaveCheckboxChanged($event, item)" />
                        <checkbox v-if="isGodUser || isAdmin" v-model="item.lock" :id="key" :checked="item.lock" :disabled="item.lockDisabled" class="w-12 ml-12 justify-center" @change="onLockedCheckboxChanged($event, item)" />
                        <checkbox v-else :value="item.lock" :checked="item.lock" :id="key" :disabled="true" class="w-12 ml-12 justify-center" />
                    </div>
                </div>
            </template>

            <div slot="footer" class="flex justify-center w-full">
                <button class="btn btn-primary" :disabled="isModalDisabled" @click="sendTemplate">
                    Save
                </button>
            </div>
        </modal>    

        <modal
            :show="showBookingModal"
            :closeable="true"
            :click-to-close="false"
            :escapeable="false"
            size="sm"
            @hide="hideBookdingModal"
        >
            <h2 slot="header">Booking created</h2>
            <div slot="body">
                <p v-text="bookingText" class="mb-4" />
                <div v-if="bookingData" class="mb-2">
                    <p>
                        <span>Consignment id: </span>
                        <component
                            :is="apportServiceLink ? 'a' : 'span'"
                            :href="apportServiceLink"
                            target="_blank"
                            :class="{ 'text-transparent linear-background': loadingUnifaun }"
                        >
                            {{ bookingData.consignmentId }}
                        </component>
                    </p>
                    <p v-text="`Order no: ${bookingData.orderNo || '-'}`" class="mt-2" />
                </div>
            </div>
            <div slot="footer" class="flex flex__column flex__align-center w-full justify-center">
                <p v-if="user.azure_id" class="flex w-full justify-center px-2">
                    <component
                        :is="loadingUnifaun ? 'div' : 'a'"
                        :href="apportServiceLink || apportSsoLink"
                        :disabled="loadingUnifaun"
                        target="_blank"
                        class="warning-link btn inline-block height-50"
                    >
                        Open created booking
                    </component>
                </p>
                <div class="warning-text border-red-500 px-6 py-1 text-center mt-4 bg-orange-200">
                    <p>
                        In case you are facing vOne login page, you may
                    </p>
                    <p class="flex justify">
                        <a :href="apportSsoLink" target="_blank" class="flex w-full justify-center flex__align-center">
                            click here to authorize with Azure <AzureIcon />
                        </a>
                    </p>
                    <p>
                        or enter ordinary vOne credentials.
                    </p>
                    <p>
                        On authorization you can click the button again
                    </p>
                    <h4 class="warning-header uppercase text-red-500 mt-4">Warning</h4>
                    <p>
                        Please note price may alter in case you will
                    </p>
                    <p>
                        change booking details in vOne
                    </p>
                </div>
                <button class="btn btn-primary mt-4" @click="hideBookdingModal">
                    Home page
                </button>
            </div>
        </modal>

        <modal :show="azureModals.warning.show" :closeable="false" size="sm" @hide="azureModals.warning.show = false">
            <h2 slot="header" v-text="azureModals.warning.header" />
            <div slot="body" class="pb-6">
                <p v-text="azureModals.warning.body" />
            </div>
            <div slot="footer" class="flex flex__justify-center w-full">
                <button type="button" class="btn" :class="{ 'btn-loading': isAzureInviting }" @click="inviteAzure">
                    <span :class="{ 'opacity-0': isAzureInviting }">
                        Invite me to Azure
                    </span>
                </button>
                <button type="button" class="btn-transparent ml-4" @click="azureModals.warning.show = false">
                    Skip
                </button>
            </div>
        </modal>

        <modal :show="azureModals.inviteSuccess.show" :closeable="false" size="sm" @hide="closeAzureModals">
            <h2 slot="header" v-text="azureModals.inviteSuccess.header" />
            <div slot="body" class="pb-6">
                <p v-text="azureModals.inviteSuccess.body" />
            </div>
            <div slot="footer" class="flex flex__justify-center w-full">
                <button type="button" class="btn-transparent ml-4" @click="closeAzureModals">
                    OK
                </button>
            </div>
        </modal>

        <modal :show="showDocumentsModal" size="sm" @hide="closeDocumentsModal">
            <h2 slot="header" v-text="'Order created'" />
            <div slot="body" class="pb-6">
                <p>
                    Booking was successfully created!
                    In some minutes consignment {{ bookingData?.consignmentNo }} will be available on Shipments tab.
                </p>
                <div class="relative my-2">
                    <AppSpinner v-show="loadingDocuments" :size="14" class="flex w-full justify-center mb-2" />
                    <div
                        v-for="(item, i) in documents"
                        :key="i"
                        class="mb-3 doc-icon flex flex__align-center flex__justify-between color-blue f-size-14 cursor-pointer"
                        @click="download(item)"
                    >
                        <div class="flex flex__align-center">
                            <svg class="mr-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.0417 6.09359V1.6665H4.53125C4.09831 1.6665 3.75 2.01481 3.75 2.44775V17.5519C3.75 17.9849 4.09831 18.3332 4.53125 18.3332H15.4688C15.9017 18.3332 16.25 17.9849 16.25 17.5519V6.87484H11.8229C11.3932 6.87484 11.0417 6.52327 11.0417 6.09359ZM13.5303 12.9738L10.3916 16.089C10.1751 16.3042 9.82552 16.3042 9.60905 16.089L6.47038 12.9738C6.13997 12.646 6.37174 12.0832 6.83659 12.0832H8.95833V9.479C8.95833 9.19124 9.19141 8.95817 9.47917 8.95817H10.5208C10.8086 8.95817 11.0417 9.19124 11.0417 9.479V12.0832H13.1634C13.6283 12.0832 13.86 12.646 13.5303 12.9738ZM16.0221 5.08447L12.8353 1.89437C12.6888 1.74788 12.4902 1.6665 12.2819 1.6665H12.0833V5.83317H16.25V5.6346C16.25 5.42953 16.1686 5.23096 16.0221 5.08447Z" fill="#00A3FF"/>
                            </svg>

                            <div target="_blank" class="break-words">
                                {{ item.filename }}
                            </div>
                        </div>

                        <div class="flex ml-2">
                            <fa :icon="['fal', 'arrow-to-bottom']" />
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="flex flex__justify-center w-full">
                <button type="button" class="btn" :class="{'btn-loading': loadingUpload }" @click="downloadAll">
                    Download all
                </button>
                <button type="button" class="btn-transparent ml-4" @click="closeDocumentsModal">
                    OK
                </button>
            </div>
        </modal>

        <template-changes-warning-modal v-model="isShowTemplateChangesWarningModal" @continue="goToTemplate(nextTemplate)" />

        <dutiable-services-modal
            :show="modals.dutiableServices.show"
            :options="dutiableServices"
            @select="selectDutiableService"
        />

        <modal :show="modals.dutiableServicesNoOptions.show" size="sm" hide-header>
            <div slot="body" class="mt-6 mb-4">
                <p class="f-s-16">
                    Sorry, the carrier service you have selected can not ensure delivery of the cargo you specified. Please, change the cargo parameters, change the template or contact support team.
                </p>
            </div>
            <div slot="footer" class="flex justify-center w-full">
                <button
                    class="btn"
                    @click="setModalShow(modals.dutiableServicesNoOptions.name, false)"
                >
                        Got it!
                </button>
            </div>
        </modal>

        <modal :show="modals.dutiableServicesNotFound.show" size="sm" :closeable="false" hide-header>
            <div slot="body" class="mt-6 mb-4 text-center">
                <h3 v-text="modals.dutiableServicesNotFound.text" />
            </div>
            <div slot="footer" class="flex justify-center w-full">
                <button class="btn" @click="setModalShow(modals.dutiableServicesNotFound.name, false)">Ok</button>
            </div>
        </modal>

        <modal :show="modals.dutiableServicesError.show" size="sm" :closeable="false" hide-header>
            <div slot="body" class="mt-6 mb-4 text-center">
                <h3 v-text="modals.dutiableServicesError.text" />
            </div>
            <div slot="footer" class="flex justify-center w-full">
                <button class="btn" @click="setModalShow(modals.dutiableServicesError.name, false)">Ok</button>
            </div>
        </modal>

        <modal :show="modals.goodsInvalid.show" size="sm" hide-header>
            <div slot="body" class="mt-6 mb-4">
                <p class="f-s-16" v-text="modals.goodsInvalid.text" />
                <ul v-if="dutiableErrors.length">
                    <li
                        v-for="(errorMessage, index) in dutiableErrors"
                        :key="`dutiable-error-message=${index}`"
                        v-text="errorMessage"
                        class="text-red-500"
                    />
                </ul>
            </div>
            <div slot="footer" class="w-full flex justify-center">
                <button
                    class="btn"
                    @click="setModalShow(modals.goodsInvalid.name, false)"
                >
                    Got it!
                </button>
            </div>
        </modal>
    </div>  
</template>

<script>
import PriceLeadtimeAddress from './price-leadtime/PriceLeadtimeAddress.vue';
import DeliveryTerms from './price-leadtime/DeliveryTerms.vue';
import Goods from "./price-leadtime/Goods";
import Checkbox from "~/components/Checkbox";
import Multiselect from 'vue-multiselect';
import Autocomplete from '~/components/Autocomplete.vue';
import DropdownBlock from '~/components/DropdownBlock.vue';
import DatePicker from "~/components/DatePicker";
import AppSpinner from '~/components/AppSpinner';
import SaveNewTemplateButton from '~/components/PriceLeadTime/SaveNewTemplateButton';
import TemplateChangesWarningModal from '~/components/PriceLeadTime/TemplateChangesWarning';
import Hint from '~/components/Hint.vue';
import CustomsBlock from '~/components/PriceLeadTime/CustomsData.vue';
import AzureIcon from '~/components/icons/azure-icon.vue';
import DutiableServicesModal from '~/components/organization/DutiableServicesModal.vue';
import Attachments from '~/components/PriceLeadTime/Attachments.vue';

import axios from 'axios';
import { requestErrors } from '~/utils/errors';
import PltMixin from './pltMixin';
import LockedMixin from './price-leadtime/locked.mixin';
import DownloadMixin from './download.mixin';
import IsTemplateMixin from '~/mixins/isTemplate.mixin';
import { DELIVERY_INSTRUCTIONS_DEFAULT } from '~/store/modules/price-leadtime';

import DateTimePicker from 'vue2-datepicker';
import QuoteModule from '~/components/PriceLeadTime/QuoteModule.vue';
import 'vue2-datepicker/index.css';

import { getAddServicesModulesParams } from '~/mixins/addServices.mixin.js';
import { FLOW_TYPE_MAP } from '~/store/modules/price-leadtime';

const payerDictionary = {
    sender: 'consignor',
    receiver: 'consignee',
}

const getIsLocked = (mode, lockedInEdit, formValue) => {
    if (mode !== 'edit') {
        return false;
    }

    if (lockedInEdit && !formValue) {
        return false;
    } 

    return Boolean(lockedInEdit);
};

const getIsGoodValid = (good) => {
    return Boolean(
        good.goodsType
        && good.goodsDescription
        && good.netWeight
        && good.statisticalNo
        && good.countryOfOrigin
        && good.unitOfMeasureCode
        && good.numberOfGoodsItems
        && good.unitPrice
    );
};

const createDatetimeByDate = (time) => {
    const [hours, minutes] = time.split(':');
    const date = new Date();

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds('00');

    return date;
};

export default {
    name: 'PriceLeadTimeTemplate',
    mixins: [PltMixin, IsTemplateMixin, LockedMixin, DownloadMixin],
    components: {
        PriceLeadtimeAddress,
        DeliveryTerms,
        Goods,
        Checkbox,
        Multiselect,
        Autocomplete,
        DropdownBlock,
        DatePicker,
        AppSpinner,
        SaveNewTemplateButton,
        TemplateChangesWarningModal,
        Hint,
        CustomsBlock,
        AzureIcon,
        DutiableServicesModal,
        DateTimePicker,
        QuoteModule,
        Attachments,
    },
    watch: {
        '$route.params.id'() {
            this.resetData();

            if (this.$route.params.id) {
                this.fetchTemplate(this.$route.params.id);
            }
        },
        async currentOrganization(val) {
            if (!val) {
                this.users = [];
                this.usersOptions = [];

                return;
            }

            this.isLoadingUsers = true;
            
            try {
                const url = this.isGodUser
                    ? `${this.$apiUrl.users.list}?filter[organization]=${val.id}`
                    : this.$apiUrl.users.allowed;
    
                const { data: { data } } = await axios.get(url);

                this.usersOptions = data;
                this.users = [{ id: this.user.id, name: this.user.name }];
            } catch(error) {
                this.$snotify.error(`Failed to fetch list of ${this.currentOrganization.name} users`);
            }

            this.isLoadingUsers = false;
        },
        isLoadingUsers(val) {
            if (val || !this.onUsersLoaded) {
                return;
            }

            this.onUsersLoaded();
        },
        statusesToUpdateIsServiceChecked() {
            return [
                this.dutiableServicesStatusDict.ok,
                this.dutiableServicesStatusDict.change,
                this.dutiableServicesStatusDict.goodsInvalid
            ];
        },
        isServiceCheckedTriggers(values) {
            if (this.isServiceChecked !== true) {
                return;
            }
            
            // reset isServiceChecked if
            // shipment conditions are non-dutiable
            if (values.every(val => !val)) {
                this.isServiceChecked = null;
            }
        },
        isDutiable() {
            this.resetCustomsData();
        },
        modulesUpdateTriggers: {
            deep: true,
            handler(value) {
                if (!value?.length) {
                    return;
                }

                this.fetchAddServicesModules();
            },
        },
    },
    data() {
        return {
            componentCounter: 0,
            hintValue: '',
            onUsersLoaded: null,
            isLoading: false,
            isLoadingUsers: false,
            usersOptions: [],
            users: [],
            templateRouteName: this.$route.name,
            isShowModal: false,
            isShowTemplateChangesWarningModal: false,
            name: '',
            description: '',
            loading: false,
            modalData: {},
            modalType: '',
            templates: [],
            currentTemplate: null,
            currentTemplateSearch: '',
            nextTemplate: null,
            currentOrganization: null,
            beforeChangeTemplate: null,
            templatesEndpoint: this.$apiUrl.consignments.booking.templates,
            templatesSearchEndpoint: (val) => {
                const url = `${this.templatesEndpoint}?sortKey=last_used_at&sortOrder=-1`;
                return val ? url + `&filter[search]=${val}` : url
            },
            templatesAutocompleteRef: 'templatesAutocomplete',
            dutiableServices: [],
            dutiableServicesStatus: '',
            dutiableServicesStatusDict: {
                ok: 'SERVICE_OK',
                change: 'SERVICE_NEED_CHANGE',
                notFound: 'SERVICES_NOT_FOUND',
                error: 'SERVICE_ERROR',
                goodsInvalid: 'GOODS_INVALID',
            },
            dutiableErrors: [],
            isServiceChecked: null,
            isLoadingDutiableServicesCheck: false,
        };
    },
    computed: {
        manageTemplatesLink() {
            if (this.$route.name === 'transport.priceleadtime.template') {                
                return 'transport.priceleadtime.templates'
            }

            return 'transport.transport-booking.templates';
        },
        isDutiable: {
            get() {
                return this.$store.state['price-leadtime'].isDutiable;
            },
            set(value) {
                this.$store.commit('price-leadtime/SET_IS_DUTIABLE', value);
            },
        },
        modulesUpdateTriggers() {
            return this.$store.getters['price-leadtime/modulesUpdateTriggers'];
        },
        modulesVisibility() {
            return this.$store.getters['price-leadtime/modulesVisibility'];
        },
        isShowCheckServiceButton() {
            return this.needsDutiableServicesCheck && this.dutiableServicesStatus !== this.dutiableServicesStatusDict.error;
        },
        isCreateBookingDisabled() {
            return !this.isAllValid ||
                this.needsDutiableServicesCheck ||
                this.dutiableServicesStatus === this.dutiableServicesStatusDict.error;
        },
        isCarrierServiceDutiable() {
            return this.priceLeadTimeResult.carrier_service_model?.dutiable;
        },
        needsDutiableServicesCheck() {
            // customs data form is only available DHL Express carrier
            // DHL Express carrier id is 8
            if (this.priceLeadTimeResult.carrier_model?.id !== 8) {
                return false;
            }

            if (!this.isDutiable && this.isCarrierServiceDutiable) {
                return true;
            }

            if (this.isDutiable && !this.isCarrierServiceDutiable) {
                return true;
            }

            return false;
        },
        isServiceCheckedTriggers() {
            return [this.isDutiable, this.isCarrierServiceDutiable];
        },
        
        usersComputed: {
            get() {
                return this.users;
            },
            set(val) {
                const currentUserInUsers = val.findIndex(user => user.id === this.user.id) !== -1;
                const currentUserInVal = val.findIndex(user => user.id === this.user.id) !== -1;

                if (currentUserInUsers && !currentUserInVal) {
                    return;
                }

                this.users = val;
            },
        },
        isAllowEditTemplate() {
            return this.currentTemplate && !this.currentTemplate.unifaun_pk
        },
        isGodUser() {
            return this.$store.getters['auth/isGodUser'];
        },
        isAdmin() {
            return this.$store.getters['auth/isAdmin'];
        },
        user() {
            return this.$store.getters['auth/user'];
        },
        pltData() {
            return this.$store.getters['price-leadtime/all'];
        },
        isAllValid() {
            const values = [
                this.addressData['pickup'].pickup_date,
                this.validateAddress,
                this.validateGoods,
                this.deliveryTerms.senders_reference,
            ];

            if (this.modulesVisibility['custom-clearance-module']) {
                values.push(this.isCustomsClearanceValid);
            }

            if (this.modulesVisibility['custom-data-module']) {
                values.push(this.isCustomsDataValid);
            }

            if (this.modulesVisibility['custom-value-module']) {
                values.push(this.isCustomsValueValid);
            }

            return values.every(val => val);
        },
        isCustomsClearanceValid() {
            return Boolean(
                this.customsData.invoicenumber
                && this.customsData.invoicedate
                && this.customsData.exportreason
            );
        },
        isCustomsDataValid() {
            return Boolean(
                this.customsData.goods.every(good => {
                    return getIsGoodValid(good);
                })
            );
        },
        isCustomsValueValid() {
            return Boolean(
                this.customsData.valueOfGoodsAmount
                && this.customsData.valueOfGoodsCurrency
            );
        },
        countries() {
            return this.$store.getters['countries/list'].map(country => {
                return { name: `${country.code} - ${country.name}`, value: country.code };
            });
        },
        isModalDisabled() {
            const values = [this.name.length, this.description.length];

            if (this.isGodUser) {
                values.push(this.currentOrganization?.id);
            }

            return values.some(val => !val);
        },
        displayForm() {
            return this.isGodUser || (!this.isGodUser && this.currentTemplate);
        },
        pltErrors: {
            set(value) {
                this.$store.getters['price-leadtime/activeSteps'][this.currentStep].errors = value;
            },
            get() {
                return this.$store.getters['price-leadtime/activeSteps'][this.currentStep].errors;
            }
        },
        goodsData() {
            return this.$store.getters[`price-leadtime/goods`].items;
        },
        modals() {
            return this.$store.getters['price-leadtime/modals'];
        },
        goodsTypeList: {
            get() {
                return this.$store.state['price-leadtime'].goodsTypeList;
            },
            set(value) {
                this.$store.commit('price-leadtime/SET_GOODS_TYPE_LIST', value);
            },
        },
    },
    methods: {
        changeTemplate(id) {
            if (
                this.beforeChangeTemplate === JSON.stringify({ address: this.addressData, deliveryTerms: this.deliveryTerms })
            ) {
                this.goToTemplate(id);

                return;
            }

            this.nextTemplate = id;
            this.isShowTemplateChangesWarningModal = true;
        },
        async goToTemplate(id) {
            if (!id) {
                return;
            }

            if (this.isShowTemplateChangesWarningModal) {
                this.isShowTemplateChangesWarningModal = false;
            }
            
            if (id == this.$route.params.id) {
                await this.resetData();
                this.fetchTemplate(id);

                return;
            }

            this.$router.push({ name: this.templateRouteName, params: { id } });
        },
        async resetPage() {
            this.$router.push({ name: this.templateRouteName });
        },
        async resetData() {
            this.currentTemplate = null;
            this.currentTemplateSearch = '';
            this.nextTemplate = null;
            this.name = '';
            this.description = '';
            this.currentOrganization = null;
            
            this.users = [];
            this.usersOptions = [];
            this.fullDescription = '';
            this.isDutiable = false;
            this.dutiableServices = [];
            this.dutiableServicesStatus = '';
            this.dutiableErrors = [];
            this.isServiceChecked = null;
            this.isLoadingDutiableServicesCheck = false;

            await this.$store.commit('price-leadtime/resetState');
            await this.$store.commit('price-leadtime/SET_CURRENT_TEMPLATE', null);
            this.beforeChangeTemplate = JSON.stringify({ address: this.addressData, deliveryTerms: this.deliveryTerms });
            this.activateShortFlow();
            this.setModalData();
            window.scrollTo(0, 0);
        },
        activateShortFlow() {
            this.$store.commit('price-leadtime/SET_FLOW_TYPE', FLOW_TYPE_MAP.short);
        },
        onSaveCheckboxChanged(event, field) {
            if (!event?.target?.checked) {
                field.lock = false
            }
        },
        onLockedCheckboxChanged(event, field) {
            if (field.name === 'Service' && event === false) {
                this.modalData.goods.save = false;
                this.modalData.goods.saveDisabled = true;
                this.modalData.goods.lock = false;
                this.modalData.goods.lockDisabled = true;
            }

            if (field.name === 'Service' && event === true) {
                this.modalData.goods.save = Boolean((this.goodsData[0]?.description || this.goodsData[0]?.package_type));
                this.modalData.goods.saveDisabled = !(this.goodsData[0]?.description || this.goodsData[0]?.package_type);
                this.modalData.goods.lock = getIsLocked(this.modalType, this.currentTemplate?.goods_locked, Boolean((this.goodsData[0]?.description && this.goodsData[0]?.package_type) && this.getIsLockedField('carrier')));
                this.modalData.goods.lockDisabled = !(this.goodsData[0]?.description && this.goodsData[0]?.package_type);
            }
        },
        setLockedFields(templateData) {
            const template = this.currentTemplate || templateData;
            
            if (!template) {
                return;
            }

            const lockedFields = Object.keys(template).reduce((accum, key) => {
                if (key.indexOf('locked') !== -1) {
                    const newKey = key.substring(0, key.indexOf('_locked'));
                    accum[newKey] = template[key];
                }

                return accum;
            }, {});

            this.$store.commit('price-leadtime/SET_LOCKED_FIELDS', lockedFields);
        },
        getHasValue(sectionName) {
            const address = this.pltData.address;

            return {
                address: [
                    address[sectionName]?.name,
                    address[sectionName]?.country?.value,
                    address[sectionName]?.zip,
                    address[sectionName]?.address,
                    address[sectionName]?.address2,
                    address[sectionName]?.city,
                    address[sectionName]?.state,
                ].some(val => val),
                contact: [
                    address[sectionName]?.contact_person,
                    address[sectionName]?.phone,
                    address[sectionName]?.email?.replace(' ', ''),
                ].some(val => val),
            };
        },
        getIsLockable(sectionName) {
            const address = this.pltData.address;

            return {
                address: [
                    address[sectionName]?.name,
                    address[sectionName]?.country?.value,
                    address[sectionName]?.zip,
                    address[sectionName]?.address,
                    address[sectionName]?.city,
                ].every(val => val),
                contact: [
                    address[sectionName]?.contact_person,
                    address[sectionName]?.phone,
                    address[sectionName]?.email?.replace(' ', ''),
                ].every(val => val),
            };
        },
        setModalData() {
            const consignorHasValue = this.getHasValue('consignor');
            const consigneeHasValue = this.getHasValue('consignee');
            const pickupHasValue = this.getHasValue('pickup');
            const deliveryHasValue = this.getHasValue('delivery');

            const consignorIsLockable = this.getIsLockable('consignor');
            const consigneeIsLockable = this.getIsLockable('consignee');
            const pickupIsLockable = this.getIsLockable('pickup');
            const deliveryIsLockable = this.getIsLockable('delivery');

            const isCarrierServiceLocked = getIsLocked(this.modalType, this.currentTemplate?.carrier_service_locked, this.priceLeadTimeResult?.carrier_service_model?.id);

            this.modalData = {
                pickup_time: {
                    name: 'Pickup time',
                    save: Boolean(this.addressData.pickup.pickupTimeEarliest.value || this.addressData.pickup.pickupTimeLatest.value),
                    saveDisabled: !(this.addressData.pickup.pickupTimeEarliest.value || this.addressData.pickup.pickupTimeLatest.value),
                    lock: getIsLocked(this.modalType, this.currentTemplate?.pickup_time_locked, this.addressData.pickup.pickupTimeEarliest.value || this.addressData.pickup.pickupTimeLatest.value),
                    lockDisabled: !(this.addressData.pickup.pickupTimeEarliest.value || this.addressData.pickup.pickupTimeLatest.value),
                },
                payer: {
                    name: 'Freight payer',
                    save: Boolean(payerDictionary[this.pltData.deliveryTerms.payer] || 'third party'),
                    saveDisabled: !(payerDictionary[this.pltData.deliveryTerms?.payer] || 'third party'),
                    lock: getIsLocked(this.modalType, this.currentTemplate?.payer_locked, payerDictionary[this.pltData.deliveryTerms.payer] || 'third party'),
                    lockDisabled: !(payerDictionary[this.pltData.deliveryTerms?.payer] || 'third party'),
                },
                consignor: {
                    name: 'Sender company',
                    save: consignorHasValue.address,
                    saveDisabled: !consignorHasValue.address,
                    lock: getIsLocked(this.modalType, this.currentTemplate?.consignor_address_locked, consignorHasValue.address),
                    lockDisabled: !consignorIsLockable.address,
                },
                consignorContact: {
                    name: 'Sender contact person',
                    save: consignorHasValue.contact,
                    saveDisabled: !consignorHasValue.contact,
                    lock: getIsLocked(this.modalType, this.currentTemplate?.consignor_contact_locked, consignorHasValue.contact),
                    lockDisabled: !consignorIsLockable.contact,
                },
                consignee: {
                    name: 'Reciever company',
                    save: consigneeHasValue.address,
                    saveDisabled: !consigneeHasValue.address,
                    lock: getIsLocked(this.modalType, this.currentTemplate?.consignee_address_locked, consigneeHasValue.address),
                    lockDisabled: !consigneeIsLockable.address,
                },
                consigneeContact: {
                    name: 'Reciever contact person',
                    save: consigneeHasValue.contact,
                    saveDisabled: !consigneeHasValue.contact,
                    lock: getIsLocked(this.modalType, this.currentTemplate?.consignee_contact_locked, consigneeHasValue.contact),
                    lockDisabled: !consigneeIsLockable.contact,
                },
                pickup: {
                    name: 'Pickup company',
                    save: pickupHasValue.address,
                    saveDisabled: !pickupHasValue.address,
                    lock: getIsLocked(this.modalType, this.currentTemplate?.pickup_address_locked, pickupHasValue.address),
                    lockDisabled: !pickupIsLockable.address,
                },
                pickupContact: {
                    name: 'Pickup contact person',
                    save: pickupHasValue.contact,
                    saveDisabled: !pickupHasValue.contact,
                    lock: getIsLocked(this.modalType, this.currentTemplate?.pickup_contact_locked, pickupHasValue.contact),
                    lockDisabled: !pickupIsLockable.contact,
                },
                delivery: {
                    name: 'Delivery company',
                    save: deliveryHasValue.address,
                    saveDisabled: !deliveryHasValue.address,
                    lock: getIsLocked(this.modalType, this.currentTemplate?.delivery_address_locked, deliveryHasValue.address),
                    lockDisabled: !deliveryIsLockable.address,
                },
                deliveryContact: {
                    name: 'Delivery contact person',
                    save: deliveryHasValue.contact,
                    saveDisabled: !deliveryHasValue.contact,
                    lock: getIsLocked(this.modalType, this.currentTemplate?.delivery_contact_locked, deliveryHasValue.contact),
                    lockDisabled: !deliveryIsLockable.contact,
                },
                service: {
                    name: 'Service',
                    save: Boolean(this.priceLeadTimeResult?.carrier_service_model?.id),
                    saveDisabled: !this.priceLeadTimeResult?.carrier_service_model?.id,
                    lock: isCarrierServiceLocked,
                    lockDisabled: !this.priceLeadTimeResult?.carrier_service_model?.id,
                },
                goods: {
                    name: 'Goods description & package type',
                    save: Boolean((this.goodsData[0]?.description || this.goodsData[0]?.package_type) && isCarrierServiceLocked),
                    saveDisabled: !isCarrierServiceLocked || !(this.goodsData[0]?.description || this.goodsData[0]?.package_type),
                    lock: getIsLocked(this.modalType, this.currentTemplate?.goods_locked, Boolean((this.goodsData[0]?.description && this.goodsData[0]?.package_type) && this.getIsLockedField('carrier'))),
                    lockDisabled: !isCarrierServiceLocked || !(this.goodsData[0]?.description && this.goodsData[0]?.package_type),
                },
                pickupInstructions: {
                    name: 'Pickup instructions',
                    save: Boolean(this.pltData.deliveryTerms.pickup_instructions?.instruction),
                    saveDisabled: !this.pltData.deliveryTerms.pickup_instructions?.instruction,
                    lock: getIsLocked(this.modalType, this.currentTemplate?.pickup_instruction_locked, this.pltData.deliveryTerms.pickup_instructions?.instruction),
                    lockDisabled: !this.pltData.deliveryTerms.pickup_instructions?.instruction,
                },
                deliveryInstructions: {
                    name: 'Delivery instructions',
                    save: Boolean(this.pltData.deliveryTerms.delivery_instructions?.instruction),
                    saveDisabled: !this.pltData.deliveryTerms.delivery_instructions?.instruction,
                    lock: getIsLocked(this.modalType, this.currentTemplate?.delivery_instruction_locked, this.pltData.deliveryTerms.delivery_instructions?.instruction),
                    lockDisabled: !this.pltData.deliveryTerms.delivery_instructions?.instruction,
                },
            }

            if (this.modalType !== 'create') {
                return;
            }

            if (!this.isAdmin && !this.isGodUser) {
                return;
            }

            if (this.isAdmin && !this.isGodUser) {
                this.currentOrganization = this.user.organization;

                return;
            }

            if (this.currentOrganization) {
                return;
            }

            const organizationId = this.user.sitesFilter[0]?.organization?.id;
            if (!organizationId) {
                return;
            }

            this.currentOrganization = this.getOrganizationById(organizationId);
        },
        showCreateModal() {
            this.modalType = 'create';
            this.setModalData();
            this.isShowModal = true;
        },
        showEditModal() {
            if (!this.isAllowEditTemplate) {
                return;
            }

            this.modalType = 'edit';
            this.setModalData();
            this.isShowModal = true;
        },
        hideModal() {
            this.isShowModal = false;
        },
        async sendTemplate() {
            this.loading = true;
            const isEdit = this.modalType === 'edit';

            const payload = this.getTemplateData();

            try {
                let url = this.templatesEndpoint;

                if (isEdit) {
                    url += `/${this.currentTemplate.id}`;
                }

                const { data: { data } } = await axios.post(url, payload);
                this.hideModal();

                this.$snotify.success(isEdit ? 'Template updated' : 'Template created');

                this.goToTemplate(data.id);
                this.$refs[this.templatesAutocompleteRef].fetchOptions();
                
            } catch(error) {
                this.$snotify.error(requestErrors(error))
            }

            this.modalType = '';
            this.loading = false;
        },
        getTemplateData() {
            const address = this.pltData.address;
            const deliveryTerms = this.pltData.deliveryTerms;

            const payload = {
                name: this.name,
                description: this.description,
                organization_id: this.isGodUser ? this.currentOrganization.id : this.user.organization.id,
                users: this.users.map(user => user.id),
                full_description: this.fullDescription,
            };

            if (this.modalData.pickup_time.save) {
                payload.earliest_pickup_time = this.formatDatetimeToTime(this.addressData.pickup.pickupTimeEarliest.value);
                payload.latest_pickup_time = this.formatDatetimeToTime(this.addressData.pickup.pickupTimeLatest.value);
            }

            if (this.modalData.consignor.save) {
                payload.fromName = address.consignor?.name;
                payload.fromCountryCode = address.consignor?.country?.value;
                payload.fromZipCode = address.consignor?.zip;
                payload.fromAddress = address.consignor?.address;
                payload.fromAddress2 = address.consignor?.address2;
                payload.fromCity = address.consignor?.city;
                payload.fromState = address.consignor?.state;
            }

            if (this.modalData.consignorContact.save) {
                payload.fromContactName = address.consignor?.contact_person;
                payload.fromContactPhone = address.consignor?.phone;
                payload.fromContactEmail = address.consignor?.email?.replace(' ', '');
                payload.fromUnifaunAddressId = address.consignor?.unifaun_address_id;
            }

            if (this.modalData.consignee.save) {
                payload.toName = address.consignee?.name;
                payload.toCountryCode = address.consignee?.country?.value;
                payload.toZipCode = address.consignee?.zip;
                payload.toAddress = address.consignee?.address;
                payload.toAddress2 = address.consignee?.address2;
                payload.toCity = address.consignee?.city;
                payload.toState = address.consignee?.state;
            }

            if (this.modalData.consigneeContact.save) {
                payload.toContactName = address.consignee?.contact_person;
                payload.toContactPhone = address.consignee?.phone;
                payload.toContactEmail = address.consignee?.email?.replace(' ', '');
                payload.toUnifaunAddressId = address.consignee?.unifaun_address_id;
            }

            if (this.modalData.pickup.save) {
                payload.senderName = address.pickup?.name;
                payload.senderCountryCode = address.pickup?.country?.value;
                payload.senderZipCode = address.pickup?.zip;
                payload.senderAddress = address.pickup?.address;
                payload.senderAddress2 = address.pickup?.address2;
                payload.senderCity = address.pickup?.city;
                payload.senderState = address.pickup?.state;

            }

            if (this.modalData.pickupContact.save) {
                payload.senderContactName = address.pickup?.contact_person;
                payload.senderContactPhone = address.pickup?.phone;
                payload.senderContactEmail = address.pickup?.email?.replace(' ', '');
                payload.senderUnifaunAddressId = address.pickup?.unifaun_address_id;
            }

            if (this.modalData.delivery.save) {
                payload.receiverName = address.delivery?.name;
                payload.receiverCountryCode = address.delivery?.country?.value;
                payload.receiverZipCode = address.delivery?.zip;
                payload.receiverAddress = address.delivery?.address;
                payload.receiverAddress2 = address.delivery?.address2;
                payload.receiverCity = address.delivery?.city;
                payload.receiverState = address.delivery?.state;
            }

            if (this.modalData.deliveryContact.save) {
                payload.receiverContactName = address.delivery?.contact_person;
                payload.receiverContactPhone = address.delivery?.phone;
                payload.receiverContactEmail = address.delivery?.email?.replace(' ', '');
                payload.receiverUnifaunAddressId = address.delivery?.unifaun_address_id;
            }

            if (this.modalData.service.save) {
                payload.carrierServiceId = this.priceLeadTimeResult?.carrier_service_model?.id;
            }

            if (this.modalData.payer.save) {
                payload.payer = payerDictionary[deliveryTerms.payer] || 'third party';
            }

            if (this.modalData.pickupInstructions.save) {
                payload.pickupInstructions =  deliveryTerms.pickup_instructions?.instruction;
            }

            if (this.modalData.deliveryInstructions.save) {
                payload.deliveryInstructions = deliveryTerms.delivery_instructions?.instruction;
            }

            if (this.modalData.goods.save) {
                payload.goods_description = this.goodsData[0].description;
                payload.goods_package_type = this.goodsData[0].package_type?.code || null;
            }

            payload.pickup_time_locked = this.modalData.pickup_time.lock;
            payload.pickup_address_locked = this.modalData.pickup.lock;
            payload.pickup_contact_locked = this.modalData.pickupContact.lock;
            payload.delivery_address_locked = this.modalData.delivery.lock;
            payload.delivery_contact_locked = this.modalData.deliveryContact.lock;
            payload.consignor_address_locked = this.modalData.consignor.lock;
            payload.consignor_contact_locked = this.modalData.consignorContact.lock;
            payload.consignee_address_locked = this.modalData.consignee.lock;
            payload.consignee_contact_locked = this.modalData.consigneeContact.lock;
            payload.carrier_service_locked = this.modalData.service.lock;
            payload.pickup_instruction_locked = this.modalData.pickupInstructions.lock;
            payload.delivery_instruction_locked = this.modalData.deliveryInstructions.lock;
            payload.payer_locked = this.modalData.payer.lock;
            payload.goods_locked = this.modalData.goods.lock;

            return payload;
        },
        getCountryByCode(countryCode) {
            return this.countries.find(country => country?.value === countryCode);
        },
        getOrganizationById(id) {
            return this.organizations.find(org => org.id === id);
        },
        async initGoodsType() {
            const value = this.priceLeadTimeResult?.carrier_model?.id || this.priceLeadTimeResult?.carrier_service_model?.carrier_id; 

            if (!value) {
                return;
            }

            const query = `?filter[carrier_id]=${value}`;
            const result = await axios.get(`/api/good-type-dictionary${query}`)

            this.goodsTypeList = result?.data?.data?.filter(item => item.name) || [];

            if (!this.goodsTypeList.length) {
                this.$snotify.success('No package type found for selected carrier. Please choose another one.');
            }
        },
        async setCurrentTemplate(data) {
            await this.$store.commit('price-leadtime/resetState');
            this.$store.commit('price-leadtime/SET_CURRENT_TEMPLATE', data);

            this.activateShortFlow();
            this.setLockedFields(data);

            const address = this.pltData.address;
            const deliveryTerms = this.pltData.deliveryTerms;

            this.currentTemplateSearch = data.name;
            this.name = data.name;
            this.description = data.description;
            this.currentOrganization = this.getOrganizationById(data.organization_id);
            this.fullDescription = data.full_description;

            if (data.users?.length) {
                // sets this.users value after
                // currentOrganization  watcher is finished
                this.onUsersLoaded = () => {
                    this.users = data.users;
                    this.onUsersLoaded = null;
                };
            }

            // pickup datetime
            if (data.earliest_pickup_time) {
                this.addressData.pickup.pickupTimeEarliest.value = createDatetimeByDate(data.earliest_pickup_time);
            }

            if (data.latest_pickup_time) {
                this.addressData.pickup.pickupTimeLatest.value = createDatetimeByDate(data.latest_pickup_time);
            }            

            // address consignor
            address.consignor.name = data.consignor_address?.name;
            address.consignor.country = this.getCountryByCode(data.consignor_address?.city?.country_code);
            address.consignor.zip = data.consignor_address?.postcode;
            address.consignor.address = data.consignor_address?.address;
            address.consignor.address2 = data.consignor_address?.address2;
            address.consignor.city = data.consignor_address?.city?.name;
            address.consignor.state = data.consignor_address?.state;
            address.consignor.contact_person = data.consignor_contact?.name;
            address.consignor.unifaun_address_id = data.consignor_address?.unifaun_address_id;

            address.consignor.phone = data.consignor_contact?.phone
            address.consignor.email = data.consignor_contact?.email

            // address consignee
            address.consignee.name = data.consignee_address?.name;
            address.consignee.country = this.getCountryByCode(data.consignee_address?.city?.country_code);
            address.consignee.zip = data.consignee_address?.postcode;
            address.consignee.address = data.consignee_address?.address;
            address.consignee.address2 = data.consignee_address?.address2;
            address.consignee.city = data.consignee_address?.city?.name;
            address.consignee.state = data.consignee_address?.state;
            address.consignee.contact_person = data.consignee_contact?.name;
            address.consignee.unifaun_address_id = data.consignee_address?.unifaun_address_id;

            address.consignee.phone = data.consignee_contact?.phone;
            address.consignee.email = data.consignee_contact?.email;

            // address pickup
            address.pickup.name = data.pickup_address?.name;
            address.pickup.country = this.getCountryByCode(data.pickup_address?.city?.country_code);
            address.pickup.zip = data.pickup_address?.postcode;
            address.pickup.address = data.pickup_address?.address;
            address.pickup.address2 = data.pickup_address?.address2;
            address.pickup.city = data.pickup_address?.city?.name;
            address.pickup.state = data.pickup_address?.state;
            address.pickup.contact_person = data.pickup_contact?.name;
            address.pickup.unifaun_address_id = data.pickup_address?.unifaun_address_id;

            address.pickup.phone = data.pickup_contact?.phone;
            address.pickup.email = data.pickup_contact?.email;

            // address pickup
            address.delivery.name = data.delivery_address?.name;
            address.delivery.country = this.getCountryByCode(data.delivery_address?.city?.country_code);
            address.delivery.zip = data.delivery_address?.postcode;
            address.delivery.address = data.delivery_address?.address;
            address.delivery.address2 = data.delivery_address?.address2;
            address.delivery.city = data.delivery_address?.city?.name;
            address.delivery.state = data.delivery_address?.state;
            address.delivery.contact_person = data.delivery_contact?.name;
            address.delivery.unifaun_address_id = data.delivery_address?.unifaun_address_id;

            address.delivery.phone = data.delivery_contact?.phone;
            address.delivery.email = data.delivery_contact?.email;

            this.componentCounter++;

            // this.priceLeadTimeResult.carrier_service_model.id = data.carrierServiceId
            const payerData = Object.entries(payerDictionary)
                .find(([_, value]) => value === data.payer);
            deliveryTerms.payer = payerData ? payerData[0] : Object.keys(payerDictionary)[0];

            const deliveryInstructionId = data.delivery_address?.delivery_instruction_id || data.delivery_instruction_id;
            if (deliveryInstructionId) {
                try {
                    const deliveryInstructionsResponse = await axios.get(this.$apiUrl.consignments.bookingInstructions + `/${deliveryInstructionId}`);

                    this.$store.commit('price-leadtime/SET_DELIVERY_TERMS_FIELD', {
                        field: 'delivery_instructions',
                        value: deliveryInstructionsResponse?.data?.data || { instruction: '' },
                    });
                } catch (_) {
                    deliveryTerms.delivery_instructions = { ...DELIVERY_INSTRUCTIONS_DEFAULT };
                }
            }

            const pickupInstructionId = data.pickup_address?.pickup_instruction_id || data.pickup_instruction_id;
            if (pickupInstructionId) {
                try {
                    const pickupInstructionsResponse = await axios.get(this.$apiUrl.consignments.bookingInstructions + `/${pickupInstructionId}`);

                    this.$store.commit('price-leadtime/SET_DELIVERY_TERMS_FIELD', {
                        field: 'pickup_instructions',
                        value: pickupInstructionsResponse?.data?.data || { instruction: '' },
                    });
                } catch (_) {
                    deliveryTerms.pickup_instructions = { ...DELIVERY_INSTRUCTIONS_DEFAULT };
                }
            }

            if (data.carrier_id) {
                try {
                    const carrierResponse = await axios.get(this.$apiUrl.carriers.base + `/${data.carrier_id}`);
                    await this.$store.commit('price-leadtime/SET_CARRIER_MODEL', carrierResponse?.data?.data);
                } catch (_) {
                    await this.$store.commit('price-leadtime/SET_CARRIER_MODEL', null);
                }
            }

            if (data.carrier_service_id) {
                try {
                    const serviceResponse = await axios.get(this.isGodUser || this.isTemplate ? `${this.$apiUrl.carrierServices.base}?limit=10000` : this.$apiUrl.carrierServices.limited);
                    await this.$store.commit(
                        'price-leadtime/SET_CARRIER_SERVICE_MODEL',
                        serviceResponse?.data?.data?.find(item => item.id === data.carrier_service_id)
                    );
                } catch (_) {
                    await this.$store.commit('price-leadtime/SET_CARRIER_SERVICE_MODEL', null);
                }
            }

            if (data.goods_description) {
                this.$store.commit('price-leadtime/SET_GOODS_ITEM_BY_INDEX', { index: 0, fieldName: 'description', value: data.goods_description });
            }

            await this.initGoodsType();

            if (data.goods_package_type && this.$store.state['price-leadtime'].goodsTypeList.length) {
                this.$store.commit(
                    'price-leadtime/SET_GOODS_ITEM_BY_INDEX',
                    {
                        index: 0,
                        fieldName: 'package_type',
                        value: this.$store.state['price-leadtime'].goodsTypeList.find(type => type.code === data.goods_package_type),
                    }
                );
            }

            this.beforeChangeTemplate = JSON.stringify({ address, deliveryTerms });

            this.modalData.pickup.lock = data.pickup_address_locked;
            this.modalData.pickupContact.lock = data.pickup_contact_locked;
            this.modalData.delivery.lock = data.delivery_address_locked;
            this.modalData.deliveryContact.lock = data.delivery_contact_locked;
            this.modalData.consignor.lock = data.consignor_address_locked;
            this.modalData.consignorContact.lock = data.consignor_contact_locked;
            this.modalData.consignee.lock = data.consignee_address_locked;
            this.modalData.consigneeContact.lock = data.consignee_contact_locked;
            this.modalData.service.lock = data.carrier_service_locked;
            this.modalData.pickupInstructions.lock = data.pickup_instruction_locked;
            this.modalData.deliveryInstructions.lock = data.delivery_instruction_locked;
            this.modalData.goods.lock = data.goods_locked;

            this.currentTemplate = data;
        },
        async fetchTemplate(id) {
            try {
                this.isLoading = true;
                
                const { data: { data } } = await axios.get(this.templatesEndpoint + `/${id}`);

                await this.setCurrentTemplate(data);

                this.isLoading = false;
            } catch (error) {
                console.error(error);
                this.$snotify.error(requestErrors(error));
            }
        },
        async sendDutiableServicesCheck(templateId) {
            this.isLoadingDutiableServicesCheck = true;
            
            try {
                const { data: { data } } = await axios.post(this.$apiUrl.apport.dutiableProducts, this.getBookingData(templateId));

                this.dutiableServicesStatus = data.status;
                this.dutiableServices = data.services;
                this.dutiableErrors = data.allErrors || [];

                if (
                    this.dutiableServicesStatus === this.dutiableServicesStatusDict.change
                ) {
                    this.isLoadingDutiableServicesCheck = false;
                    this.setModalShow(
                        this.dutiableServices.length
                            ? this.modals.dutiableServices.name
                            : this.modals.dutiableServicesNoOptions.name,
                        true
                    );

                    return;
                }

                if (this.dutiableServicesStatus === this.dutiableServicesStatusDict.goodsInvalid) {
                    this.isLoadingDutiableServicesCheck = false;
                    this.setModalShow(this.modals.goodsInvalid.name, true);

                    return;
                }

                if (this.dutiableServicesStatus === this.dutiableServicesStatusDict.error) {
                    this.isLoadingDutiableServicesCheck = false;
                    this.setModalShow(this.modals.dutiableServicesError.name, true);

                    return;
                }

                if (this.dutiableServicesStatus === this.dutiableServicesStatusDict.notFound) {
                    this.isLoadingDutiableServicesCheck = false;
                    this.setModalShow(this.modals.dutiableServicesNotFound.name, true);

                    return;
                }

                if (this.dutiableServicesStatus === this.dutiableServicesStatusDict.ok) {
                    this.isLoadingDutiableServicesCheck = false;
                    this.isServiceChecked = true;
                    this.$snotify.success('Current carrier service is valid!');
                }
            } catch (error) {
                console.error(error);
                this.$snotify.error(requestErrors(error));
            }

            this.isLoadingDutiableServicesCheck = false;
        },
        async selectDutiableService(service) {
            this.setModalShow(this.modals.dutiableServices.name, false);

            // move viewport to goods section
            // and highlight package type select red
            this.pltErrors = {
                ...this.pltErrors,
                ...this.goods.reduce((output, _, index) => {
                    output[`items.${index}.package_type`] = 'multiselect-error';

                    return output;
                }, {})
            };
            document.getElementById('package-type-field').scrollIntoView({ behavior: 'smooth' });

            await this.$store.commit('price-leadtime/SET_CARRIER_SERVICE_MODEL', service);

            this.isServiceChecked = true;
        },
        setModalShow(type, value) {
            this.$store.commit('price-leadtime/SET_MODAL_SHOW_BY_TYPE', { type, value });
        },
        async fetchAddServicesModules() {
            try {
                const params = getAddServicesModulesParams(this);

                if (!params) {
                    return;
                }

                const { data: { data } } = await axios.get(
                    this.$apiUrl.addServices.modules + `?${params}`
                );

                this.isDutiable = Boolean(data.dutiable);

                if (data.modules_visibility) {
                    this.$store.dispatch('price-leadtime/setModules', data.modules_visibility);
                }
            } catch (error) {
                if (error.response?.status === 404) {
                    this.$store.commit('price-leadtime/RESET_MODULES');

                    return;
                }

                this.$snotify.error(requestErrors(error));
                console.error(error);
            }
        },
    },
    async created() {
        this.resetData();

        const promises = [
            this.fetchProxio(),
            this.fetchOrganizations(),
        ];

        if (!this.$store.getters['countries/list']?.length) {
            promises.push(this.$store.dispatch('countries/init'));
        }

        await Promise.all(promises);

        if (this.$route.params.id) {
            this.fetchTemplate(this.$route.params.id);
        }

        this.setModalData();
        this.activateShortFlow();

        this.checkAzureId();

    },
    beforeDestroy() {
        this.resetData();
    },
}
</script>

<style lang="scss">
#template-autocomplete {
    .cinput-autocomplete__item {
        padding: 0;
    }

    .template-autocomplete__item {
        padding: 6px 12px;
    }
}
.template-modal-row {
    background: #F7F7F7;
}

.template-modal-list {
    height: 280px;
    overflow: auto;

    &__head {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fff;
    }
}
</style>